import React, { ReactNode, useState } from "react";
import { Container, EmptyContent, OptionDescription, OptionLabel, OptionsWrapper, Wrapper } from "./style";
import "./style.css";
import { ReactComponent as DropdownIcon } from "../../../assets/icons/arrow-down.svg";
import { Select } from "antd";

const { Option } = Select;

interface InputProps {
  options?: Array<{
    label: string;
    value: any;
    description?: string;
  }> | any;
  value?: any;
  $width?: string;
  $height?: string;
  $showBorder?: boolean;
  placeholder?: string;
  field?: any;
  disableLabel?: boolean;
  showSearch?: boolean;
  disabled?: boolean;
  label?: string;
  onChange?: (val) => void;
  defaultValue?: string;
  dropdownClassName?: string;
  children?: ReactNode;
  $showArrow?: boolean;
  $showSelector?: boolean;
  optionLabelProp?: string;
  filterOption?: (inputValue: string, option: any) => boolean;
  lightBorder?: boolean
}

const CustomSelect = ({
  disableLabel = false,
  showSearch = false,
  disabled = false,
  field,
  onChange,
  defaultValue,
  dropdownClassName,
  label,
  children,
  filterOption,
  options,
  optionLabelProp="",
  lightBorder = false,
  ...props
}: InputProps) => {
  const [hasFocus, setHasFocus] = useState(false);

  return (
    <Container $disabled={disabled} $lightBorder={lightBorder}>
      {field?.value?.length && (
        <p
          className={`input-label ${hasFocus ? "top" : ""} ${
            field?.value?.length ? "top" : ""
          }`}
        >
          {props.placeholder}
        </p>
      )}
      <Wrapper
        {...props}
        {...field}
        onChange={(value) => {
          field && field.onChange(value);
          onChange && onChange(value);
        }}
        onFocus={() => {
          setHasFocus(true);
        }}
        onBlur={() => {
          setHasFocus(false);
        }}
        suffixIcon={<DropdownIcon />}
        popupClassName="filter-dropdown"
        defaultValue={defaultValue}
        showSearch={showSearch}
        disabled={disabled}
        label={label}
        notFoundContent={<EmptyContent>Please select</EmptyContent>}
        filterOption={filterOption}
        optionLabelProp={optionLabelProp}
      >
         {options?.map((option) => (
          <OptionsWrapper key={option.value} value={option.value} label={option.label} $description={option.description}>
            <OptionLabel>
              {option.label}
            </OptionLabel>
            {option.description && (
              <OptionDescription>
                {option.description}
              </OptionDescription>
            )}
          </OptionsWrapper>
        ))}
        {children}
      </Wrapper>
    </Container>
  );
};

export default CustomSelect;