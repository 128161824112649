import styled from "styled-components";
import * as ResponsiveBreakpoint from "../../responsiveBreakpoints";

export const Container = styled.div`
  @media${ResponsiveBreakpoint.largerThanDesktop} {
    background: linear-gradient(90deg, #ffffff 50%, #000000 50%);
  }
`;

export const Wrapper = styled.div`
  background: #ffffff;
  //height: 100vh;
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  
  @media${ResponsiveBreakpoint.largerThanDesktop} {
    justify-content: flex-end;
  }
`;

export const LeftDiv = styled.div`
  padding: 64px;
  max-height: 100vh;
  max-width: 1428px;
  flex: 0 0 calc(100% - 450px);
  @media${ResponsiveBreakpoint.largerThanMediumDesktop} {
    flex: 0 0 calc(100% - 492px);
  }
  @media${ResponsiveBreakpoint.tab} {
    flex: 0 0 100%;
  }

  @media${ResponsiveBreakpoint.desktop} {
    padding: 64px;
  }
  @media${ResponsiveBreakpoint.tab} {
    padding: 64px 59px;
  }
  @media${ResponsiveBreakpoint.smallTab} {
    padding: 57px 40px;
  }
  @media${ResponsiveBreakpoint.mobile} {
    padding: 50px;
  }
  @media${ResponsiveBreakpoint.smallMobile} {
    padding: 36px 16px;
  }
`;
export const ImgContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 600px;
  
  img {
    width: 900px;
    position: absolute;
    top: -20px;
    left: -50px;
  }
`;
export const RightDiv = styled.div`
  background-color: #000000;
  border-right: 1px solid #000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 40px;
  max-height: 100vh;
  align-items: flex-start;
  overflow: hidden;
  flex: 0 0 450px;
  position: relative;
  @media${ResponsiveBreakpoint.largerThanMediumDesktop} {
    flex: 0 0 492px;
  }
  @media (min-width: 1921px) {
    flex: 0 0 calc(50% - 960px + 492px);
  }
  @media${ResponsiveBreakpoint.tab} {
    display: none;
  }

  p {
    font-family: 'Athletics';
    width: 100%;
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 52px;
    text-align: center;
    letter-spacing: -0.8px;
    color: #ffffff;
    top: 0;
    left: 0;
    padding: 100px 64px 0;
    max-width: 450px;

    @media${ResponsiveBreakpoint.largerThanMediumDesktop} {
      max-width: 492px;
    }
  }
  @media (max-width: 1440px) {
    & > p {
    }
  }
  @media${ResponsiveBreakpoint.desktop} {
    p {
      font-size: 32px;
      line-height: 48px;
    }
  }
  @media${ResponsiveBreakpoint.tab} {
    display: none;
  }
`;

export const TopDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  column-gap: 1rem;
`;

export const BodyDiv = styled.div`
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 0rem;
  overflow: auto;
`;
