import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../app/api";

interface Props {
  firstName?: string;
  lastName?: any;
  email?: string;
  phoneNumber?: string;
  businessId?: string;
  appUrl?: string;
  activationToken?: string;
  password?: string;
  passwordToken?: string;
  token?: string;
  otp?: string;
  teamId?: string;
  memberId?: string;
  roleName?: string;
  description?: string;
  permissions?: string[];
  userId?: string;
}

export const InviteTeam = createAsyncThunk(
  "Invite/multi-team",
  async (
    {
      firstName,
      lastName,
      email,
      phoneNumber,
      businessId,
      appUrl,
      roleName,
    }: Props,
    { rejectWithValue },
  ) => {
    try {
      const response = await api.post(`/auth/business/team`, {
        firstName,
        lastName,
        email,
        phoneNumber,
        businessId,
        appUrl,
        roleName,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const ActivateTeamAccount = createAsyncThunk(
  "Invite/activation-token",
  async ({ token }: Props, { rejectWithValue }) => {
    try {
      const response = await api.post(`/auth/business/team/invite/${token}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const setTeamPassword = createAsyncThunk(
  "Invite/set-password",
  async ({ password, token }: Props, { rejectWithValue }) => {
    try {
      const response = await api.post(`/auth/business/team/invite/password`, {
        password,
        token,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const SetTeamPasswordToken = createAsyncThunk(
  "Invite/set-password-token",
  async ({ passwordToken }: Props, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `/auth/business/team/invite?otp=${passwordToken}`,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const GetAllInvitedTeam = createAsyncThunk(
  "Invite/get-all-team",
  async ({ businessId }: Props, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `/auth/business/team?businessId=${businessId}`,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const VerifyTeamOTP = createAsyncThunk(
  "Invite/verify-otp",
  async ({ otp }: Props, { rejectWithValue }) => {
    try {
      const response = await api.post(`/auth/business/team/invite?otp=${otp}`);
      const { token } = response.data.data.loginData;
      localStorage.setItem("token", token);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const DisableTeam = createAsyncThunk(
  "Invite/disable/team",
  async ({ teamId }: Props, { rejectWithValue }) => {
    try {
      const response = await api.put(
        `auth/business/team/deactivate?memberId=${teamId}`,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);
export const UpdateTeamData = createAsyncThunk(
  "Invite/update-team",
  async ({ memberId, phoneNumber, roleName }: Props, { rejectWithValue }) => {
    try {
      const response = await api.put(`auth/business/team`, {
        memberId,
        phoneNumber,
        roleName,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const ResendInvitation = createAsyncThunk(
  "Invite/resend-invite",
  async ({ memberId, appUrl }: Props, { rejectWithValue }) => {
    try {
      const response = await api.post(`auth/business/team/resend`, {
        memberId,
        appUrl,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const ResendOTP = createAsyncThunk(
  "Invite/resend-otp",
  async ({ email }: Props, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `auth/business/team/invite/activate/resend/${email}`,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const CreateRole = createAsyncThunk(
  "role/create-role",
  async (
    { roleName, description, permissions, businessId }: Props,
    { rejectWithValue },
  ) => {
    try {
      const response = await api.post(
        `auth/business/team/${businessId}/roles`,
        { roleName, description, permissions },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const GetAllRoles = createAsyncThunk(
  "roles/get-all-role",
  async ({ businessId }: Props, { rejectWithValue }) => {
    try {
      const response = await api.get(`auth/business/team/${businessId}/roles`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);
export const GetRolePermissions = createAsyncThunk(
  "roles/get-role-permissions",
  async ({ businessId, roleName }: Props, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `auth/business/team/${businessId}/roles/${roleName}/permissions`,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);
export const GetBusinessUserRole = createAsyncThunk(
  "roles/get-business-user-role",
  async ({ businessId, userId }: Props, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `auth/business/team/${businessId}/roles/${userId}`,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);
