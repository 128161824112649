import { useEffect, useState } from "react"
import { useAppDispatch } from "../hooks"
import { getRequiredBusinessDocuments } from "../../pages/Onboarding/redux/actions"
import * as Sentry from "@sentry/react";

type Props = {
  businessTypeId: number;
}

type RequiredDocsDetails = {
  id: number;
  document: string;
}

export const useRequiredDocs = ({ businessTypeId }: Props) => {
  const [requiredDocs, setRequiredDocs] = useState<RequiredDocsDetails[]>([])
  const dispatch = useAppDispatch()

  useEffect(() => {
    const fetchRequiredDocuments = async () => {
      const country = 'NG'
      const action = await dispatch(getRequiredBusinessDocuments({ businessTypeId, country }))

      if (getRequiredBusinessDocuments.fulfilled.match(action)) {
        const listOfRequiredDocs = action.payload.map((item: any) => ({
          id: item.businessDocument.id,
          document: item.businessDocument.document
        }))
        setRequiredDocs(listOfRequiredDocs)
      } else if (getRequiredBusinessDocuments.rejected.match(action)) {
        Sentry.captureException(action)
      }
    }

    fetchRequiredDocuments()
  }, [businessTypeId])

  return { requiredDocs }
}