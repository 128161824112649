import styled, { css } from "styled-components";
import DatePicker from "../common/defaults/Datepicker";

const { RangePicker } = DatePicker;

export const Wrapper = styled.div`
  p {
    margin-bottom: unset;
  }
`;

export const TopDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 32px;
  width: 100%;
  overflow-x: auto;
  
  @media (min-width: 992px) {
    gap: 0;
    overflow: hidden;
    justify-content: space-between;
  }

  &::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
`;

interface SalesCardDivProps {
    $theme: "primary" | "secondary";
  }

export const SalesCardDiv = styled.div<SalesCardDivProps>`
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 8px;
  width: 240px;
  min-width: 240px;
  height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  ${(props: { $theme: "primary" | "secondary" }) => {
    if (props.$theme === "primary") {
      return css`
        background: #ffffff;
        color: #000000;
      `;
    } else if (props.$theme === "secondary") {
      return css`
        background: #000000;
        color: #ffffff;
      `;
    }
  }};

  @media (min-width: 576px) {
    min-width: 214px;
    height: 208px;
  }

  @media (min-width: 992px) {
    width: 24%;
    height: 237px;
  }
`;

export const OverviewHead = styled.div`
  margin-bottom: 26px;

  @media (max-width: 1200px) {
    margin-bottom: 16px;
  }
  @media (max-width: 375px) {
    margin-bottom: 26px;
  }
`;

export const OverviewBody = styled.div`
  flex: 1 1;
`;

export const ChartCardBody = styled.div`
  width: 190px;
  height: 68px;

  @media (min-width: 1440px) {
    width: 100% !important;
  }
`;

export const OverviewFooter = styled.div`
  margin-top: 20px;
`;

export const OverviewLabel = styled.p`
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  padding-bottom: 4px;
`;

export const OverviewValue = styled.p`
  font-family: "Athletics";
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.4px;
`;

export const BottomDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SalesChartControls = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 12px 0;

  @media (min-width: 768px) {
    margin: 20px 0;
    width: 67%;
  }

  @media (min-width: 992px) {
    width: 69.5%;
  }

  @media (min-width: 1440px) {
    width: 71.5%;
  }
`;

export const SalesChartControlsLeft = styled.p`
  font-family: "Athletics";
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.4px;
  color: #000000;
`;

interface SalesChartControlsButtonProps {
    variant?: boolean;
  }

export const SalesChartControlsButton = styled.button<SalesChartControlsButtonProps>`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 10px;
  border: ${(props: { variant?: boolean }) =>
    props.variant ? "none" : "1px solid #000000"};
  background-color: ${(props: { variant?: boolean }) =>
    props.variant ? "#e5e5e580" : "transparent"};
  border-radius: 8px;
  padding: 12px;

  &:disabled {
    pointer-events: none;
  }

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }

  & span {
    font-family: "Inter";
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.02em;
  }

  @media (min-width: 768px) {
    margin-top: 0;
  }
`;

export const SalesChartDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 20px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 8px;
  height: 268px;
  overflow-x: auto;

  @media (min-width: 768px) {
    width: 67%;
    padding: 15px 24px;
    height: 288px;
  }

  @media (min-width: 992px) {
    width: 69.5%;
  }

  @media (min-width: 1440px) {
    width: 71.5%;
  }
`;

export const SalesChartContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (min-width: 768px) {
    gap: 0;
    justify-content: space-between;
    flex-direction: row;
  }
`;

export const LineDiv = styled.div`
  height: 77%;
  font-family: "Inter";
`;

export const SalesChartButtonsContainer = styled.div`
  position: relative;
  height: 15%;
  width: 100%;
  font-size: 12px;

  @media (min-width: 768px) {
    font-size: 14px;
  }
`;

export const SalesTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  cursor: pointer;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  background-color: #f7f7f7;
  height: 95%;
  width: 85px;

  @media screen and (min-width: 768px) {
    gap: 10px;
    width: 100px;
  }
  @media screen and (min-width: 1200px) {
    display: none;
  }
`;

interface SalesChartButtonsProps {
    showButtons?: boolean;
  }

export const SalesChartButtons = styled.div<SalesChartButtonsProps>`
  position: absolute;
  top: 105%;
  display: ${(props: { showButtons?: boolean }) =>
    props.showButtons ? "flex" : "none"};
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #000000;
  padding: 10px 20px 5px 7px;
  border-radius: 8px;
  background-color: white;
  z-index: 3;

  @media screen and (min-width: 1200px) {
    height: 100%;
    display: flex;
    flex-direction: row;
    position: static;
    border: 0;
    padding: 0;
    border-radius: 0;
  }
`;

interface SalesButtonProps {
  date: string;
  value: string;
  custom: boolean;
}

export const SalesButton = styled.button<SalesButtonProps>`
  padding-bottom: 14px;
  cursor: pointer;
  font-family: "Inter";
  line-height: 16px;

  @media (min-width: 1200px) {
    padding: 0 22px;
    border-width: 1px 0 1px 1px;
    background-color: ${({ date, value }: SalesButtonProps) =>
      date === value ? "#F7F7F7" : "#FFFFFF"};
    border-color: #e8e8e8;
    border-style: solid;
    height: 100%;
    overflow: hidden;

    &:nth-child(1) {
      border-radius: ${({ custom }) =>
        custom ? "8px 8px 8px 8px" : "8px 0 0 8px"};
      border-width: ${({ custom }) => custom && "1px"};
      display: ${({ custom }) => custom && "flex"};
      align-items: ${({ custom }) => custom && "center"};
      gap: ${({ custom }) => custom && "10px"};
    }

    &:nth-child(6) {
      border-width: 1px;
      border-radius: 0 8px 8px 0;
    }
  }
`;

export const DatePickerRange = styled.div`
  position: absolute;
  top: -1%;
  left: 100px;

  @media (min-width: 768px) {
    left: 120px;
  }

  @media (min-width: 1024px) {
    left: 140px;
  }

  @media (m-width: 1500px) {
    left: 160px;
  }
`;

export const DateRange = styled(RangePicker)`
  width: 195px;
  height: 33px;
  border: 1px solid #e8e8e8 !important;
  border-radius: 6px;
  box-shadow: none;

  :hover {
    border-color: #a3a3a3 !important;
  }

  & div[class="ant-picker-input"] > input {
    font-family: "Inter";
    font-size: 12px;
    line-height: 24px;
    letter-spacing: -0.02em;
  }

  & span[class="ant-picker-clear"] {
    color: black;
    background-color: white;
  }

  & div[class="ant-picker-input"] > input:placeholder-shown {
    font-weight: 400;
    color: #565c69 !important;
  }

  & div[class="ant-picker-input"] > input::placeholder {
    font-family: "Inter";
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #565c69 !important;
  }

  & > div[class="ant-select-selector"] {
    height: 100% !important;
    border-radius: 8px !important;
    border: none !important;
    box-shadow: none !important;
  }

  & div[class~="ant-picker-active-bar"] {
    background-color: #000000;
  }

  @media (min-width: 768px) {
    width: 270px;
    height: 39px;

    & div[class="ant-picker-input"] > input {
      font-size: 16px;
    }
    & div[class="ant-picker-input"] > input::placeholder {
      font-size: 24px;
    }
  }
`;

export const DatePickerWrapper = styled.div`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
`;

export const WireCardDiv = styled.div`
  border-radius: 8px;
  background-color: var(--primary-pink);
  color: #ffffff;
  width: 100%;
  padding: 24px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  height: 268px;

  svg {
    position: absolute;
  }

  p {
    font-family: "Athletics";
    font-weight: 300;
    font-size: 20px;
    line-height: 32px;
    color: #ffffff;
    z-index: 1;
  }

  button {
    z-index: 1;
  }

  @media (min-width: 768px) {
    width: 31%;
    height: 288px;
  }

  @media (min-width: 992px) {
    width: 29%;

    p {
      font-weight: 400;
      font-size: 28px;
      line-height: 34px;
      letter-spacing: -0.56px;
    }
  }

  @media (min-width: 1440px) {
    width: 27%;
  }
`;

