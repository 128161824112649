import React, { useContext, useState } from "react";
import { Controller } from "react-hook-form";
import { Button } from "../../../components/common/Button";
import { useForm } from "react-hook-form";
import { Types } from "../Types";
import { BusinessTitle, BusinessText, FormsFlex, ErrorText } from "../styles";
import { Input as AntInput } from "antd";
import BusinessLogoUpload from "../../../components/compliance/Steps/components/StepOne/BusinessLogoUpload";
import FormSection from "../components/FormSection";
import FormField from "../../../components/FormField/FormField";
import { TextAreaContainer } from "../../../components/compliance/Steps/components/StepOne/style";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { addBusinessInformation } from "../redux/actions";
import Loader from "../../../components/common/Loader";
import { NetworkErrorAlertContext } from "../../../context/NetworkErrorAlert";
import { useBusinessTypes } from "../../../app/hooks/useBusinessTypes";
import CustomSelect from "../../../components/common/CustomSelect";
import { useBusinessCategory } from "../../../app/hooks/useBusinessCategory";
import CountrySelect from "../../../components/common/CountrySelect";
import { updateBusinessType } from "../redux/slice";
import { getCountryByAbbreviation } from "country-currency-map";
import { defineBusinessTypes } from "../utils/defineBusinessTypes";
import { updateBusinessId } from "../../Login/usersSlice";

type Props = {
  handleNext: () => void;
};

const BusinessInfo = ({ handleNext }: Props) => {
  const [fileUrl, setFileUrl] = useState<any>(null);
  const dispatch = useAppDispatch();
  const { businessTypes } = useBusinessTypes();
  const { businessCategories } = useBusinessCategory();
  const { businessId, userObj } = useAppSelector((state) => state.users);
  const { accountUser } = userObj;

  const { onShowAlert: onShowErrorAlert } =
    useContext(NetworkErrorAlertContext) || {};
  const { loading } = useAppSelector((state) => state.onboarding || {});

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<Types>({ mode: 'onChange' });

  const handleAddBusinessInfo = async (data: Types) => {
    const businessData = {
      owner: accountUser.id,
      logoUrl: fileUrl,
      organizationType: data.businessType,
      ...data,
    };

    const action = await dispatch(addBusinessInformation(businessData));
   
    if (addBusinessInformation.fulfilled.match(action)) {
      dispatch(updateBusinessId(action.payload.id));
      dispatch(updateBusinessType(businessData?.businessType as any))
      handleNext();
    } else if (addBusinessInformation.rejected.match(action)) {
      onShowErrorAlert("", String(action.payload));
    }
  };

  const definedBusinessTypes = defineBusinessTypes(businessTypes)

  return (
    <div>
      <Loader isLoading={loading} />
      <BusinessTitle>Business Information</BusinessTitle>
      <BusinessText>Provide basic details about your business</BusinessText>

      <FormSection>
        <FormField
          name="businessName"
          control={control}
          label="Legal Business Name"
          defaultValue={accountUser?.businessName}
          required
          errors={errors}
        />
      </FormSection>

      <FormSection>
        <Controller
          name="businessType"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <CustomSelect
              placeholder="Type of business"
              options={definedBusinessTypes}
              $height="40px"
              $width="100%"
              field={field}
              optionLabelProp="label"
            />
          )}
        />
        {errors.businessType?.type === "required" && (
          <ErrorText>
            <span>Field is required</span>
          </ErrorText>
        )}
      </FormSection>

      <FormSection>
        <Controller
          name="businessCategory"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <CustomSelect
              placeholder="Business sector"
              options={businessCategories}
              $height="40px"
              $width="100%"
              field={field}
            />
          )}
        />
        {errors.businessCategory?.type === "required" && (
          <ErrorText>
            <span>Field is required</span>
          </ErrorText>
        )}
      </FormSection>

      <FormSection>
        <CountrySelect value={getCountryByAbbreviation(accountUser?.businessCountry)} disabled />
      </FormSection>

      <FormSection>
        <FormField
          name="address"
          control={control}
          label="Business Address"
          required
          errors={errors}
        />
      </FormSection>

      <FormsFlex>
        <FormField
          name="city"
          control={control}
          label="City"
          required
          errors={errors}
        />
        <FormField
          name="state"
          control={control}
          label="State"
          required
          errors={errors}
        />
      </FormsFlex>

      <FormSection>
        <TextAreaContainer>
          <Controller
            name="description"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <AntInput.TextArea
                rows={5}
                placeholder="Business Description"
                {...field}
              />
            )}
          />
          {errors.description && (
            <ErrorText>
              <span>Field is required</span>
            </ErrorText>
          )}
        </TextAreaContainer>
      </FormSection>

      <FormSection>
        <h1 className="business-logo-copy">
          Add your business logo (optional)
        </h1>
        <p className="business-logo-text">
          Your business logo will be included on all transaction receipts issued
          to your customers.
        </p>
        <BusinessLogoUpload
          onFileChange={(data) => setFileUrl(data)}
          setFileUrl={setFileUrl}
          fileUrl={fileUrl}
        />
      </FormSection>

      <Button
        onClick={handleSubmit(handleAddBusinessInfo)}
        label="Continue"
        width="494px"
        height="48px"
        fontSize="16px"
        disabled={!isValid || loading}
      />
    </div>
  );
};

export default BusinessInfo;
