import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Props } from "../types";
import { CurrencyTypeWrapper, Header } from "../styles";
import { AnimatePresence, motion } from "framer-motion";
import SameCurrencyPayout from "./SameCurrencyPayout";
import InternationalPayout from "./InternationalPayout";
import { nextVariants } from "../../../utils";
import { clearUserData, setActiveCurrency } from "../../../redux/formDataSlice";
import { SameCurrencyAndIntWrapper } from "../styles";

const Amount = ({ setStep }: Props) => {
  const currencyTypes: string[] = ["Same currency", "International"];
  const activeCurrency = useSelector(
    (state: any) => state.formData.activeCurrency
  );
  const dispatch = useDispatch();

  const handleCurrencyTypeClick = (currencyType: string) => {
    dispatch(clearUserData());
    dispatch(setActiveCurrency(currencyType));
  };

  return (
    <SameCurrencyAndIntWrapper>
      <Header>How much do you want to send?</Header>
      <CurrencyTypeWrapper>
        {currencyTypes.map((currencyType) => (
          <p
            key={currencyType}
            className={activeCurrency === currencyType ? "active" : "inactive"}
            onClick={() => handleCurrencyTypeClick(currencyType)}
          >
            {currencyType}
          </p>
        ))}
      </CurrencyTypeWrapper>

      <AnimatePresence>
        <motion.div
          variants={nextVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          key={activeCurrency}
        >
          {activeCurrency === "Same currency" ? (
            <SameCurrencyPayout setStep={setStep} />
          ) : (
            <InternationalPayout setStep={setStep} />
          )}
        </motion.div>
      </AnimatePresence>
    </SameCurrencyAndIntWrapper>
  );
};

export default Amount;
