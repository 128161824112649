import React from "react";
import { Wrapper } from "./style";

const CustomRadio = ({
  label,
  value,
  onClick,
  selected,
  border,
}: {
  label: string;
  value: string;
  onClick: () => void;
  selected: boolean;
  border?: string;
}) => {
  return (
    <Wrapper border={border} value={value} onClick={onClick} className={selected ? "selected" : ""} checked={selected}>
      {label}
    </Wrapper>
  );
};

export default CustomRadio;