import React, { useState } from "react";
import { SocialWrapper, Dropdown, DropdownItem } from "../styles";

type SocialMediaPickerProps = {
  addonBefore?: string | string[];
  placeholder?: string;
  value?: string | Record<string, string>;
  onChange?: (value: string | Record<string, string>) => void;
};

const SocialMediaPicker: React.FC<SocialMediaPickerProps> = ({
  addonBefore,
  placeholder = "Enter your username or profile link here...",
  value,
  onChange,
}) => {
  const [selectedOption, setSelectedOption] = useState<string>(
    Array.isArray(addonBefore) ? addonBefore[0] : addonBefore || ""
  );

  const handleOptionChange = (newOption: string) => {
    setSelectedOption(newOption);
  };

  const handleInputChange = (inputValue: string) => {
    if (Array.isArray(addonBefore)) {
      const updatedValues: any = Array.isArray(value) ? [...value] : [];
      const optionIndex = addonBefore.indexOf(selectedOption);

      updatedValues[optionIndex] = inputValue;

      onChange?.(updatedValues.filter(Boolean));
    } else {
      onChange?.(inputValue);
    }
  };

  const renderAddonBefore = () => {
    if (Array.isArray(addonBefore)) {
      return (
        <Dropdown
          defaultValue={addonBefore[0]}
          onChange={(val: any) => handleOptionChange(val)}
        >
          {addonBefore.map((option, index) => (
            <DropdownItem key={index} value={option}>
              {option}
            </DropdownItem>
          ))}
        </Dropdown>
      );
    }
    return addonBefore ? <span>{addonBefore}</span> : null;
  };

  const getCurrentInputValue = () => {
    if (Array.isArray(addonBefore) && Array.isArray(value)) {
      const optionIndex = addonBefore.indexOf(selectedOption);
      return value[optionIndex] || "";
    }
    return typeof value === "string" ? value : "";
  };

  return (
    <SocialWrapper
      addonBefore={renderAddonBefore()}
      value={getCurrentInputValue()}
      onChange={(e) => handleInputChange(e.target.value)}
      placeholder={placeholder}
    />
  );
};

export default SocialMediaPicker;