interface BusinessTypesFromBackend {
    label: string;
    value: number;
}

interface DefinedBusinessTypes {
    label: string;
    value: number;
    description: string;
}

const businessTypeDescriptions: Record<number, string> = {
    2: "Also known as sole proprietorship. This is a registered business operated by one person.",
    5: "A starter business is an unregistered small business that is in its early stages.",
    40: "A registered company is any company that has officially registered to business i.e. Private Limited Liability Company (Ltd), Public Limited Company (PLC) Unlimited Company.",
    41: "Any registered Charities, Religious Organizations, Board of Trustees, or Non-Profit making Organizations"
};

export const defineBusinessTypes = (
    businessTypes: BusinessTypesFromBackend[]
): DefinedBusinessTypes[] => {
    return businessTypes.map((type) => ({
        ...type,
        description: businessTypeDescriptions[type.value] || "No description available"
    }));
};