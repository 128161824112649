import React, { ReactNode, useState } from "react";
import { Container, Wrapper, Label } from "./style";
import "./style.css";
import { ReactComponent as DropdownIcon } from "../../../assets/icons/arrow-down.svg";

interface InputProps {
  options?: any[];
  value?: any;
  $width?: string;
  $height?: string;
  placeholder?: string;
  field?: any;
  disableLabel?: boolean;
  onChange?: (val) => void;
  defaultValue?: string;
  disabled?: boolean;
  children?: ReactNode;
  lightBorder?: boolean;
  animatedLabel?: string;
}

const CustomSelectTwo = ({
  disableLabel = false,
  disabled = false,
  field,
  onChange,
  defaultValue,
  children,
  lightBorder,
  ...props
}: InputProps) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  
  const handleFocus = (focused: boolean) => setIsFocused(focused);
  
  const handleSelectChange = (selectedOption) => {
    if (onChange) {
      onChange(selectedOption);
    }
  };

  const hasValue = !!props.value || !!defaultValue;
  const isLabelActive = isFocused || hasValue;

  return (
    <Container $lightBorder={lightBorder}>
      {!disableLabel && props.animatedLabel && (
        <Label 
          className={isLabelActive ? "active" : ""}
          $hasValue={hasValue}
          $isFocused={isFocused}
        >
          {props.animatedLabel}
        </Label>
      )}

      <Wrapper
        {...props}
        {...field}
        onChange={handleSelectChange}
        suffixIcon={<DropdownIcon />}
        popupClassName="filter-dropdown"
        defaultValue={defaultValue}
        disabled={disabled}
        title="custom-select"
        onFocus={() => handleFocus(true)}
        onBlur={() => handleFocus(false)}
        placeholder={props.animatedLabel ? '' : props.placeholder}
        $lightBorder={lightBorder}
        value={props.value}
      >
        {children}
      </Wrapper>
    </Container>
  );
};

export default CustomSelectTwo;
