import { Button } from "../../../../../../components/common/Button";
import {
  ContentWrapper,
  DetailsBox,
  DetailsContent,
  DetailsRow,
  DetailsValue,
  ItemWrapper,
} from "../styles";
import React, { useContext, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { merchantBusinessKeys } from "../../../../../Settings/settingsSlice";
import { encryptAlgorithm } from "../../../../../../utils/encryptionAlgorithm";
import { payoutRequestV2, setLoading } from "../../../../redux/slice";
import Loader from "../../../../../../components/common/Loader";
import { NetworkErrorAlertContext } from "../../../../../../context/NetworkErrorAlert";
import { sendOTP } from "../../../../../../api/usersAPI";
import WalletOTP from "../../../../components/SendFunds/components/WalletOTP";
import {
  DetailsLabel,
  DetailsSectionHeader,
  EditButton,
  SectionTitle,
} from "../../../../components/Withdrawal/styles";
import { useNavigate, useParams } from "react-router-dom";
import {
  formatCurrencyAmount,
  getCountryName,
} from "../../../../../../utils/currency";
import dayjs from "dayjs";
import { formatValue } from "../../../../utils";
import { clearUserData, setStep } from "../../../../redux/formDataSlice";
import { SuccessAlertContext } from "../../../../../../context/SuccessAlertContext";

type Props = {
  handlePrevious?: () => void;
};

type SenderAddress = {
  city?: string;
  streetAddress?: string;
  countryCode?: string;
  postcode?: string;
  state?: string;
};

type PayoutRequest = {
  receiverIdType?: string;
  receiverLastName?: string;
  cardNumber?: string;
  receiverFirstName?: string;
  senderAddress?: SenderAddress;
  cardHolderName?: string;
  bankCode?: string;
  senderNationality?: string;
  senderIdType?: string;
  purpose?: string;
  quotationId?: string;
  accountName?: string;
  creditAccountCountry?: string;
  accountType?: string;
  senderFirstName?: string;
  senderLastName?: string;
  receiverIdNumber?: string;
  senderIdNumber?: string;
  bankName?: string;
  accountNumber?: string;
  requestId?: string;
  receiverMobileNumber?: string;
  receiverEmail?: string;
  senderBirthDate?: string;
  receiverRelationship?: string;
};

const Review = ({ handlePrevious }: Props) => {
  const getSavedData = useAppSelector((state) => state.formData.cnyData);
  const [verifyOTP, setVerifyOTP] = useState(false);
  const { currencyCode } = useParams();
  const { businessId } = useAppSelector((state) => state.users);
  const { keys } = useAppSelector((state) => state.settings);
  const { loading } = useAppSelector((state) => state.wallets || {});
  const accountUser = useAppSelector(
    (state) => state.users.userObj?.accountUser,
  );

  const cardHolderFullName = `${getSavedData?.cardholderFirstName} ${getSavedData?.cardholderLastName}`;
  const senderFullName = `${getSavedData?.senderFirstName} ${getSavedData?.senderLastName}`;
  const beneficiaryFullName = `${getSavedData?.beneficiaryFirstName} ${getSavedData?.beneficiaryLastName}`;
  const totalAmount = getSavedData.sourceAmount + getSavedData.fee;

  const PaymentType =
    getSavedData?.paymentMethod === "unionpay" ? "Card" : "Bank account";

  const currentDate = dayjs().format("YYYY-MM-DD");

  const senderCountry = getCountryName(getSavedData?.senderCountry);

  const { onShowAlert: onShowSuccessAlert } =
    useContext(SuccessAlertContext) || {};
  const { onShowAlert: onShowErrorAlert } =
    useContext(NetworkErrorAlertContext) || {};

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (businessId) {
      dispatch(merchantBusinessKeys(businessId));
    }
  }, [businessId, dispatch]);

  const publicKey = keys?.data?.publicKey;

  const handlePayout = async () => {
    const payoutRequest: PayoutRequest = {
      receiverIdType: getSavedData?.beneficiaryID,
      receiverLastName: getSavedData?.beneficiaryLastName,
      cardNumber: getSavedData?.cardNumber?.replace(/\s+/g, "") ?? undefined,
      receiverFirstName: getSavedData?.beneficiaryFirstName,
      cardHolderName: cardHolderFullName,
      bankCode: getSavedData?.bankCode || getSavedData?.bankCard,
      bankName: getSavedData?.bankCode || getSavedData?.bankCard,
      senderAddress: {
        ...getSavedData?.senderAddress,
      },
      senderNationality: getSavedData?.senderCountry,
      senderIdType: getSavedData?.senderID,
      purpose: getSavedData?.paymentPurpose,
      quotationId: getSavedData?.quotationId,
      accountName: getSavedData?.accountName,
      accountType: getSavedData?.beneficiary,
      senderFirstName: getSavedData?.senderFirstName,
      senderLastName: getSavedData?.senderLastName,
      receiverIdNumber: getSavedData?.beneficiaryIdNumber,
      senderIdNumber: getSavedData?.senderIdNumber,
      accountNumber: getSavedData?.accountNumber,
      requestId: getSavedData?.reference,
      receiverMobileNumber: getSavedData?.beneficiaryPhoneNumber,
      receiverEmail: getSavedData?.beneficiaryEmail,
      creditAccountCountry: "CN",
      senderBirthDate: getSavedData?.senderBirthDate,
      receiverRelationship: getSavedData?.relationship,
    };

    const filteredPayoutRequest = Object.fromEntries(
      Object.entries(payoutRequest).filter(
        ([key, value]) => value !== undefined,
      ),
    );

    const encryptedData = encryptAlgorithm(
      filteredPayoutRequest,
      keys?.data?.encryptionKey,
    );

    const payload = JSON.stringify({
      message: encryptedData,
    });

    const action = await dispatch(
      payoutRequestV2({ data: payload, token: publicKey }),
    );
    if (payoutRequestV2.fulfilled.match(action)) {
      onShowSuccessAlert("Payout successful");
      dispatch(clearUserData());
      navigate("/wallets");
    } else if (payoutRequestV2.rejected.match(action)) {
      onShowErrorAlert("", action.payload as string);
    }
  };

  const handleSendOTP = async (user: { email: string }) => {
    try {
      dispatch(setLoading(true));
      await sendOTP(user);
      setVerifyOTP(true);
    } catch (error) {
      onShowErrorAlert("", error?.response as string);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handlePrevStep = (index: number) => {
    dispatch(setStep(index));
  };

  return (
    <ContentWrapper>
      <Loader isLoading={loading} />

      {verifyOTP && (
        <WalletOTP setVerifyOTP={setVerifyOTP} makePayment={handlePayout} />
      )}

      <DetailsBox>
        <DetailsSectionHeader>
          <SectionTitle>Transaction details</SectionTitle>
          <EditButton onClick={() => handlePrevStep(0)}>
            <span>Edit</span>
          </EditButton>
        </DetailsSectionHeader>

        <DetailsContent>
          <DetailsRow>
            <DetailsLabel>Amount sent</DetailsLabel>
            <DetailsValue>
              {formatCurrencyAmount(currencyCode, getSavedData?.amount)}
            </DetailsValue>
          </DetailsRow>

          <DetailsRow>
            <DetailsLabel>Exchange rate</DetailsLabel>
            <DetailsValue>
              {formatCurrencyAmount("USD", getSavedData?.rate)}
            </DetailsValue>
          </DetailsRow>

          <DetailsRow>
            <DetailsLabel>Processing fee</DetailsLabel>
            <DetailsValue>
              {formatCurrencyAmount("USD", getSavedData?.fee)}
            </DetailsValue>
          </DetailsRow>

          <DetailsRow>
            <DetailsLabel>Total amount charged</DetailsLabel>
            <DetailsValue>
              {formatCurrencyAmount("USD", totalAmount)}
            </DetailsValue>
          </DetailsRow>

          <DetailsRow>
            <DetailsLabel>Purpose</DetailsLabel>
            <DetailsValue>
              {formatValue(getSavedData?.paymentPurpose)}
            </DetailsValue>
          </DetailsRow>

          <DetailsRow>
            <DetailsLabel>Payment method</DetailsLabel>
            <DetailsValue>{PaymentType}</DetailsValue>
          </DetailsRow>

          <DetailsRow>
            <DetailsLabel>Date</DetailsLabel>
            <DetailsValue>{currentDate}</DetailsValue>
          </DetailsRow>
        </DetailsContent>

        <DetailsSectionHeader>
          <SectionTitle>Sender details</SectionTitle>
          <EditButton onClick={() => handlePrevStep(1)}>
            <span>Edit</span>
          </EditButton>
        </DetailsSectionHeader>

        <DetailsContent>
          <DetailsRow>
            <DetailsLabel>Sender type</DetailsLabel>
            <DetailsValue>
              {formatValue(getSavedData?.senderType ?? "Individual")}
            </DetailsValue>
          </DetailsRow>

          <DetailsRow>
            <DetailsLabel>Sender name</DetailsLabel>
            <DetailsValue>{senderFullName}</DetailsValue>
          </DetailsRow>

          <DetailsRow>
            <DetailsLabel>Sender country</DetailsLabel>
            <DetailsValue>{senderCountry}</DetailsValue>
          </DetailsRow>
        </DetailsContent>

        <DetailsSectionHeader>
          <SectionTitle>Beneficiary’s details</SectionTitle>
          <EditButton onClick={() => handlePrevStep(2)}>
            <span>Edit</span>
          </EditButton>
        </DetailsSectionHeader>
        <DetailsContent>
          <DetailsRow>
            <DetailsLabel>Beneficiary type</DetailsLabel>
            <DetailsValue>
              {formatValue(getSavedData?.beneficiary)}
            </DetailsValue>
          </DetailsRow>

          <DetailsRow>
            <DetailsLabel>Beneficiary name</DetailsLabel>
            <DetailsValue>{beneficiaryFullName}</DetailsValue>
          </DetailsRow>

          <DetailsRow>
            <DetailsLabel>Phone number</DetailsLabel>
            <DetailsValue>{getSavedData?.beneficiaryPhoneNumber}</DetailsValue>
          </DetailsRow>
        </DetailsContent>
      </DetailsBox>

      <ItemWrapper>
        <Button
          onClick={handlePrevious}
          theme="alternate"
          height="48px"
          width="203px"
        >
          Back
        </Button>
        <Button
          onClick={() => handleSendOTP(accountUser?.email)}
          label="Continue"
          height="48px"
        />
      </ItemWrapper>
    </ContentWrapper>
  );
};

export default Review;
