import React from "react";
import { Select } from "antd";
import { StyledCurrencyInput } from "../../../../components/balances/PayoutModal/style";
import CustomSelect from "../../../../components/common/CustomSelect";
import { Wrapper, DropDownBoxCurrencyCode } from "./styles";
const { Option } = Select;

interface CurrencyProps {
  placeholder?: string;
  value?: string | number;
  className?: string;
  setValue?: (value: string) => void;
  showArrow?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectValue?: string;
  onValueChange?: (data: any) => void;
  defaultValue?: string;
  onSelectChange?: (data: string) => void;
  countries?: {
    name?: string;
    currencyCode: string;
    flagCode: string;
  }[];
  arrow?: boolean;
  selector?: boolean;
  disabled?: any;
}

const CurrencySelector = ({
  placeholder,
  value,
  selectValue,
  defaultValue,
  onValueChange,
  onSelectChange,
  disabledOptions,
  countries,
  className,
  arrow = false,
  selector = false,
  disabled,
}: CurrencyProps & { disabledOptions?: string[] }) => {
  const options = countries?.filter(
    (cur) => !disabledOptions?.includes(cur.currencyCode)
  );

  return (
    <Wrapper className={className}>
      <StyledCurrencyInput
        placeholder={placeholder}
        value={value}
        onValueChange={onValueChange}
        disabled={disabled}
      />
      <div className="select">
        <CustomSelect
          $showBorder
          dropdownClassName="dropDownBox"
          value={selectValue}
          onChange={onSelectChange}
          defaultValue={defaultValue}
          $showArrow={arrow}
          $showSelector={selector}
          lightBorder={true}
        >
          {options?.map((cur) => (
            <Option
              value={cur.flagCode}
              label={cur.flagCode}
              key={cur.flagCode}
            >
              <div className="demo-option-label-item">
                <li
                  className={`currency-flag currency-flag-${cur.currencyCode?.toLowerCase()}`}
                />
                <DropDownBoxCurrencyCode title="curr-code">
                  <span className="curr-code"> {cur.currencyCode}</span>
                </DropDownBoxCurrencyCode>
              </div>
            </Option>
          ))}
        </CustomSelect>
      </div>
    </Wrapper>
  );
};

export default CurrencySelector;
