import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { motion } from "framer-motion";
import { createWirePayment } from "../../../KlashaWire/wiresAPI";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { nextVariants } from "../../../Wallets/utils";
import {
  DetailsBox,
  DetailsContent,
  DetailsLabel,
  DetailsRow,
  DetailsSectionHeader,
  DetailsValue,
  EditButton,
  Heading,
  InputWrapper,
  PayoutWrapper,
  SectionTitle,
} from "../../styles";
import { Button } from "../../../../components/common/Button";
import { formatCurrencyCodeAmount } from "../../../../utils/currency";
import WalletOTP from "../../../Wallets/components/SendFunds/components/WalletOTP";
import { sendOTP } from "../../../../api/usersAPI";
import Loader from "../../../../components/common/Loader";
import { merchantBankAccounts } from "../../../Settings/settingsSlice";
import { requestPayout } from "../../../Balances/settlementsAPI";
import { debitWallet, sendFunds } from "../../api";
import { SuccessAlertContext } from "../../../../context/SuccessAlertContext";
import { NetworkErrorAlertContext } from "../../../../context/NetworkErrorAlert";
import { encryptAlgorithm } from "../../../../utils/encryptionAlgorithm";

interface Props {
  onGoBack: (data: number) => void;
  amount: number;
  fee: number;
  setFee: (data: number) => void;
  date: string;
  beneficiaryName: string;
  bankName: string;
  accountNumber: string;
  totalAmount: number;
  bankCode: string;
  currency?: string;
  rate?: number;
  id: string;
}

const Preview = ({
  onGoBack,
  amount,
  fee,
  date,
  beneficiaryName,
  bankName,
  accountNumber,
  totalAmount,
  bankCode,
  currency,
  id,
}: Props) => {
  const beneficiaryCurrency = currency || "NGN";
  const formData = useAppSelector((state) => state.formData);
  const { businessId, userId } = useAppSelector((state) => state.users);

  const { wallets } = useAppSelector((state) => state?.wallets);

  const successAlertContext = useContext(SuccessAlertContext);

  const { onShowAlert: onSuccess } = successAlertContext || {};

  const { onShowAlert } = useContext(NetworkErrorAlertContext);

  const navigate = useNavigate();
  const [paid, setPaid] = useState(false);
  const [savedBank, setSavedBank] = useState(null);
  const { users } = useAppSelector((state) => state);
  const [taxRefId, setTaxRefId] = useState(null);
  const dispatch = useAppDispatch();
  const { userObj } = users || {};
  const { accountUser } = userObj || {};
  const wallet = wallets?.find((wallet) => wallet.currency === "NGN");
  const [loading, setLoading] = useState(false);
  const [showOtp, setShowOtp] = useState(false);

  useEffect(() => {
    let txRefId = uuidv4();
    txRefId = `SP-${txRefId}`;
    setTaxRefId(txRefId);
  }, []);

  const handleSendOTP = async (user: { email: string }) => {
    try {
      setLoading(true);
      setShowOtp(true);
      await sendOTP(user);

      setLoading(false);
      window.close();
    } catch (err) {
      const errorObj = err?.response?.data;
      let errorMessage = "";

      if (errorObj && typeof errorObj.error === "string") {
        errorMessage = errorObj.error;
      } else if (errorObj && typeof errorObj.message === "string") {
        errorMessage = errorObj.message;
      } else {
        errorMessage = "Payout Failed";
      }

      setLoading(false);
      onShowAlert("", errorMessage);
    }
  };

  const makeNGNPayment = async () => {
    setLoading(true);
    const NGNPayout = {
      sourceCurrency: "NGN",
      destinationCurrency: "NGN",
      amount: Number(amount),
      requestId: taxRefId,
      accountHolderName: beneficiaryName,
      bankCode: bankCode,
      bankName: bankName,
      accountNumber: accountNumber,
      type: "corporate",
      email: accountUser.email,
      fee: 53.75,
      narration: "settlement payout",
      wallet: wallet?.id,
      product: "VirtualAccount",
    };

    try {
      await sendFunds(NGNPayout);

      onSuccess("Payout sent  successfully");

      setTimeout(() => {
        navigate("/settlements?=Payouts");
      }, 2000);
      setLoading(false);
    } catch (error) {
      onShowAlert("", error);
      setLoading(false);
    }
  };

  const debitInternationalWallet = async (req: string) => {
    setLoading(true);
    try {
      await debitWallet({
        fromWallet: wallet?.id,
        fee: Number(formData.processingFee),
        walletTnxId: req,
        amount: Number(formData.totalAmount),
        fromBalance: "settlement",
      });
      setLoading(false);
      onUpdatePayment(req);
      onSuccess("Payout sent successfully");
    } catch (error) {
      onShowAlert("", error);
      setLoading(false);
    }
  };

  const getMerchantAccounts = async () => {
    try {
      const res = await dispatch(merchantBankAccounts(userId));
      setSavedBank(res.payload);
    } catch (error) {
      throw new error();
    }
  };

  const findBank = savedBank?.find(
    (bank) => bank.accountNumber === accountNumber
  );

  const onRequestPayout = async () => {
    const requestPayload = {
      walletId: wallet?.id,
      payoutAmount: Number(formData.totalAmount),
      selectedAccount: findBank?.id,
      businessId,
      fee: fee ? Number(formData?.processingFee) : "",
      fromBalance: "settlement",
    };

    const encryptedData = encryptAlgorithm(requestPayload);

    const payload = {
      message: encryptedData,
    };

    setLoading(true);
    try {
      await requestPayout({ payload: payload }).then((res) => {
        const { requestId } = res;

        if (requestId) {
          debitInternationalWallet(requestId);
        }
      });
    } catch (error) {
      setLoading(false);
      onShowAlert("", error.response.data.message);
    }
  };

  useEffect(() => {
    if (userId && businessId) {
      getMerchantAccounts();
    }
  }, [userId, businessId]);

  const onUpdatePayment = async (req: string) => {
    setLoading(true);
    try {
      const wireObj = {
        sourceCurrency: currency,
        sourceAmount: totalAmount || formData.totalAmount,
        destinationCurrency: "NGN",
        destinationAmount: amount,
        schedule: true,
        total_fees: formData?.processingFee,
        totalFees: formData?.exchangeRate,
        amountToConvert: Number(amount * formData?.exchangeRate),
        rate: formData?.exchangeRate,
        paid: paid,
        amountReceived:
          Number(amount * formData.exchangeRate) +
          Number(formData?.processingFee * formData?.exchangeRate),
        business: businessId,
        merchantBeneficiary: id,
        transactionRef: req,
      };

      await createWirePayment(wireObj);
      setLoading(false);
      setPaid(true);
      setTimeout(() => {
        navigate("/settlements?=Payouts");
      }, 2000);
    } catch (error) {
      setLoading(false);
      onShowAlert("", error.response.data.message);
    }
  };

  return (
    <PayoutWrapper>
      <Heading>Review the details of your transfer</Heading>
      <motion.div
        variants={nextVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <DetailsBox>
          <DetailsSectionHeader>
            <SectionTitle>Transaction details</SectionTitle>
            <EditButton onClick={() => onGoBack(0)}>
              <span>Edit</span>
            </EditButton>
          </DetailsSectionHeader>
          <DetailsContent>
            <DetailsRow>
              <DetailsLabel>Beneficiary’s amount</DetailsLabel>
              <DetailsValue>
                {formatCurrencyCodeAmount(beneficiaryCurrency, amount)}
              </DetailsValue>
            </DetailsRow>

            <DetailsRow>
              <DetailsLabel>Processing fee</DetailsLabel>
              <DetailsValue>
                {formatCurrencyCodeAmount(
                  "NGN",
                  beneficiaryCurrency !== "NGN" ? formData.processingFee : 53.75
                )}
              </DetailsValue>
            </DetailsRow>
            <DetailsRow>
              <DetailsLabel>Total amount</DetailsLabel>
              <DetailsValue>
                {formatCurrencyCodeAmount(
                  "NGN",
                  (formData.totalAmount
                    ? formData.totalAmount
                    : totalAmount
                  ).toFixed(2)
                )}
              </DetailsValue>
            </DetailsRow>
            {currency && (
              <DetailsRow>
                <DetailsLabel>Exchange rate</DetailsLabel>
                <DetailsValue>
                  {formatCurrencyCodeAmount("NGN", formData.exchangeRate)}
                </DetailsValue>
              </DetailsRow>
            )}
            <DetailsRow>
              <DetailsLabel>Date</DetailsLabel>
              <DetailsValue>{date}</DetailsValue>
            </DetailsRow>
          </DetailsContent>

          <DetailsSectionHeader>
            <SectionTitle>Beneficiary details</SectionTitle>
            <EditButton onClick={() => onGoBack(1)}>
              <span>Edit</span>
            </EditButton>
          </DetailsSectionHeader>
          <DetailsContent>
            <DetailsRow>
              <DetailsLabel>Beneficiary</DetailsLabel>
              <DetailsValue>{beneficiaryName}</DetailsValue>
            </DetailsRow>
            <DetailsRow>
              <DetailsLabel>Bank name</DetailsLabel>
              <DetailsValue>{bankName}</DetailsValue>
            </DetailsRow>
            <DetailsRow>
              <DetailsLabel>Account number</DetailsLabel>
              <DetailsValue>{accountNumber}</DetailsValue>
            </DetailsRow>
          </DetailsContent>
        </DetailsBox>
      </motion.div>

      <InputWrapper>
        <Button
          label="Continue"
          theme="primary"
          height="46px"
          onClick={() => handleSendOTP(accountUser?.email)}
        />
      </InputWrapper>

      {showOtp && (
        <WalletOTP
          setVerifyOTP={setShowOtp}
          makePayment={currency !== null ? onRequestPayout : makeNGNPayment}
        />
      )}

      <Loader isLoading={loading} />
    </PayoutWrapper>
  );
};

export default Preview;
