import React, { useEffect, useState } from "react";
import {
  AlertBox,
  Message,
  Overlay,
} from "./style";
import { ReactComponent as CloseIcon } from "./blackClose.svg";

const AlertComponent = ({
  message,
}: {
  message: string;
}) => {
  const [show, setShow] = useState(true);
  
  const animateAlert = () => {
    const alertBox = document.getElementsByClassName(
      "error-alert-content",
    );
    if (alertBox?.[0]) {
      alertBox[0].classList.add("animate");
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      animateAlert();
    }, 100);
    return () => clearTimeout(timer);
  }, []);
  
  if (!show) return null;
  return (
    <Overlay title="error-alert" className="error-alert">
      <AlertBox className="error-alert-content">
        <span
          onClick={() => setShow(false)}
          className="close-icon"
        >
          <CloseIcon />
          <p>close</p>
        </span>
        {/* <Head></Head> */}
        <Message>{message}</Message>
      </AlertBox>
    </Overlay>
  );
};
const NetworkErrorAlert = ({
  message,
}: {
  message: string;
}) => {
  return <AlertComponent message={message} />;
};

export default NetworkErrorAlert;
