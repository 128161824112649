import { useEffect, useState } from "react";
import { getBusinessType } from "../../pages/Onboarding/redux/actions";
import { useAppDispatch } from "../hooks";
import * as Sentry from "@sentry/react";

export const useBusinessTypes = () => {
  const dispatch = useAppDispatch();
  const [businessTypes, setBusinessTypes] = useState([]);

  useEffect(() => {
    const fetchBusinessTypes = async () => {
      const action = await dispatch(getBusinessType());
      if (getBusinessType.fulfilled.match(action)) {
        const options = action.payload
          .filter((ty) => ty.name !== "Others")
          .map((type) => ({
            label: type.name,
            value: type.id,
          }));
        setBusinessTypes(options);
      } else if (getBusinessType.rejected.match(action)) {
        Sentry.captureException(action);
      }
    };

    fetchBusinessTypes();
  }, [dispatch]);

  return { businessTypes };
};
