import styled from "styled-components";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  border: 1px solid;
  border-radius: 8px;
  border-color: ${(props: {
    $isSecondaryBackground: boolean;
    $hasError: boolean;
    $height?: string;
  }) => (props.$hasError ? "#f9123b" : "#f0f0f0")} !important;
  height: ${(props) => props.$height ?? "40px"};

  :hover {
    border-color: #a3a3a3 !important;
  }

  :focus-within {
    border-color: #000000 !important;
  }
  & p {
    background-color: ${(props) =>
      props.$isSecondaryBackground ? "#fffbf7" : "#ffffff"};
    color: ${(props) => (props.$hasError ? "#f9123b" : "#a3a3a3")};
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
      opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
    &.input-label {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #565c69;
      position: absolute;
      top: ${(props) => (props.$height >= "46px" ? "12px" : "8px")};
      left: 66px;
      margin: 0;
      padding: 0;
      &.has-value {
        z-index: -1;
      }
      &.top {
        font-size: 12px;
        line-height: 16px;
        color: #2f343f;
        transform: translate(-52px, -16px);
        padding: 0 2px;
        z-index: 0;
      }
    }
  }

  & input {
    padding: 8px 14px;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    width: 100%;
    box-sizing: border-box;
    background-color: transparent;
    border: none;
    outline: none;
  }

  & input::placeholder {
    font-family: "Inter";
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #565c69 !important;
  }
`;
export const CountryCodeSpan = styled.span`
  position: absolute;
  left: 40px;
  top: 8px;
  max-width: 36px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #a39d9e;
`;
export const PhoneInputWrapper = styled(PhoneInput)`
  display: flex;
  width: 100%;
  position: relative;
  background: transparent;
  & .PhoneInputCountryIcon {
    height: 24px;
    width: 24px;
    box-shadow: none;
    background: transparent;
    outline: none;
  }
  & .PhoneInputCountrySelectArrow {
    opacity: 1;
    margin-left: 8px;
    height: 8px;
    width: 8px;
    border-width: 0 2px 2px 0;
    margin-bottom: 4px;
  }

  & > div {
    padding: 0 8px 0 12px;
    background: #ffffff;
    background: transparent;
    margin-right: 0;
  }

  & > div:after {
    position: absolute;
    border-right: 1px solid #a3a3a3;
    height: 50%;
    display: none;
    align-items: center;
    content: "";
    padding-left: 12px;
    right: 0;
  }

  & > input {
    padding: 8px 14px 8px 0;
    height: 46px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    box-sizing: border-box;
    flex: 1;
    border: unset;
    background: transparent;
    outline: none;
  }
`;
