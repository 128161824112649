import React from "react";
import {
  Wrapper,
  Bottom,
  BottomItem,
  BottomLabel,
  BottomValue,
} from "./style";
import TransactionStatus from "../../../../components/transactions/components/TransctionStatus";
import { formatLongString } from "../../../../utils/formatString";

interface Props {
  amount: string;
  date: string;
  status: string;
  transId?: string;
  type?: string;
  name?: string;
  acct?: string;
}

const WalletCard = ({
  amount,
  transId,
  date,
  type,
  status,
  name,
  acct,
}: Props) => {
  return (
    <Wrapper title="balance-card">
      <Bottom>
        {transId && (
          <BottomItem>
            <BottomLabel>Transaction ID </BottomLabel>
            <BottomValue>{formatLongString(transId, 15)}</BottomValue>
          </BottomItem>
        )}
        {name && (
          <BottomItem>
            <BottomLabel>Beneficiary Name</BottomLabel>
            <BottomValue>{name}</BottomValue>
          </BottomItem>
        )}
        {type && (
          <BottomItem>
            <BottomLabel>Transaction type</BottomLabel>
            <BottomValue>{formatLongString(type, 15)}</BottomValue>
          </BottomItem>
        )}
        <BottomItem>
          <BottomLabel>Total amount</BottomLabel>
          <BottomValue>{amount}</BottomValue>
        </BottomItem>

        {acct && (
          <BottomItem>
            <BottomLabel>Account number</BottomLabel>
            <BottomValue>{acct}</BottomValue>
          </BottomItem>
        )}
        <BottomItem>
          <BottomLabel>Date</BottomLabel>
          <BottomValue>{date}</BottomValue>
        </BottomItem>
        <BottomItem>
          <BottomLabel>Status</BottomLabel>
          <TransactionStatus text={status} />
        </BottomItem>
      </Bottom>
    </Wrapper>
  );
};

export default WalletCard;
