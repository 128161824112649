import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  border: 1px solid #F0F0F0;
  border-radius: 10px;
  margin-bottom: 24px;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const Top = styled.div`
  padding: 0 16px;
  padding-bottom: 10px;

  p{
    margin: 0;
    font-size: 10px;
    font-weight: 400;
    line-height: 15.6px; 
    font-family: "Inter";
    color: #767676;
  }

  span {
    font-style: "Athletics";
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.4px;
    margin-top: 8px;
  }
  
`;

export const Bottom = styled.div`
  display: flex;
  padding: 16px;
  flex-wrap: wrap;
  justify-content: space-between;

  & > *:nth-child(2n) {
    text-align: right;
    justify-content: flex-end;
  }
`;

export const BottomItem = styled.div`
  width: 48%;
  margin-bottom: 16px;


`;

export const BottomLabel = styled.p`
  font-family: "Inter";
  font-weight: 400;
  font-size: 10px;
  line-height: 15.6px;
  color: #767676;
  margin-bottom: 4px;
`;

export const BottomValue = styled.p`
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 0;
`;

export const BottomTransactionValue = styled.div`
  display: flex;
  justify-content: flex-end;
`