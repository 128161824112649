import React, { useContext, useState } from "react";
import {
  DocumentContainer,
  DocumentSection,
  DocumentTitle,
  NotificationBox,
  NotificationSection,
  Title,
} from "../styles";
import NotificationIcon from "../../../../../../assets/icons/notify-icon.svg";
import { DocumentUpload } from "../../../../components/DocumentUpload";
import { Button } from "../../../../../../components/common/Button";
import { Controller, useForm } from "react-hook-form";
import { NetworkErrorAlertContext } from "../../../../../../context/NetworkErrorAlert";
import { SuccessAlertContext } from "../../../../../../context/SuccessAlertContext";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import {
  CreateUSDAccount,
  sendToGoogleSheet,
  sendUploadedBASSDocument,
} from "../../../../redux/slice";
import Loader from "../../../../../../components/common/Loader";
import { formatDate } from "../../../../../../utils/dateUtils";
import { useNavigate } from "react-router-dom";

const mockBusinessData = [
  { title: "Incorporation document & any name changes" },
  { title: "Memorandum and articles of association" },
  { title: "Register of Directors" },
];

const Documents = () => {
  const [files, setFiles] = useState<{ [key: string]: File | null }>({});
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  const beneficiaries = useAppSelector((state) => state.formData.beneficiaries);
  const { businessId } = useAppSelector((state) => state.users);
  const { loading } = useAppSelector((state) => state.wallets || {});
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { onShowAlert: onShowSuccessAlert } =
    useContext(SuccessAlertContext) || {};
  const { onShowAlert: onShowErrorAlert } =
    useContext(NetworkErrorAlertContext) || {};
  const formData = useAppSelector((state) => state.formData);

  const localData = watch();

  const formattedData = formData.beneficiaries.map((person) => {
    if (person.foreignBankAccountOwnershipType === "BENEFICIARY") {
      return {
        name: person?.name,
        dob: person?.dob,
        country: person?.country,
        ownershipPercentage: person.ownershipPercentage,
        foreignBankAccountOwnershipType: person.foreignBankAccountOwnershipType,
      };
    } else if (person.foreignBankAccountOwnershipType === "DIRECTOR") {
      return {
        name: person.directorName,
        dob: person.directorDob,
        country: person.directorCountry,
        foreignBankAccountOwnershipType: person.foreignBankAccountOwnershipType,
      };
    }
    return person;
  });
  formattedData.push({
    name: localData.name,
    dob: formatDate(localData.dob),
    country: localData?.country,
    ownershipPercentage: localData.ownershipPercentage,
    foreignBankAccountOwnershipType: "BENEFICIARY",
  });

  formattedData.push({
    name: formData.directorName,
    dob: formData.directorDob,
    country: formData?.directorCountry,
    foreignBankAccountOwnershipType: "DIRECTOR",
  });

  const convertToBoolean = (data) => {
    return data === "yes";
  };

  const uploadBusinessDataToGoogleSheet = async () => {
    const action = await dispatch(sendToGoogleSheet({ formData }));
  };

  const SendKlashaComplainceDocs = async () => {
    const formData = new FormData();
    Object.keys(files).forEach((key) => {
      if (files[key]) {
        formData.append("attachment", files[key]);
      }
    });

    const action = await dispatch(
      sendUploadedBASSDocument({ id: businessId, formData })
    );

    if (sendUploadedBASSDocument.fulfilled.match(action)) {
      onShowSuccessAlert("USD account creation in progress");
      navigate("/wallets");
    }
    if (sendUploadedBASSDocument.rejected.match(action)) {
      onShowErrorAlert("", String(action.payload));
    }
  };

  const RequestUSDAccount = async () => {
    const action = await dispatch(
      CreateUSDAccount({
        data: {
          companyLegalName: formData?.companyName,
          companyRegistrationNumber: formData?.companyRegistrationNumber,
          companyCountryIncorporation: formData?.addressCountry,
          dateOfIncorporation: formData?.date,
          companyEnterpriseType: formData?.enterPriseType,
          companyType: formData?.companyType,
          website: formData?.website,
          industry: formData?.industry,
          countryTaxResidency: formData?.tax,
          companyCity: formData?.city,
          companyPostCode: formData?.code,
          companyAddressCompany: formData?.addressCountry,
          flowOfFunds: formData?.flowOfFunds,
          depositorIndustries: formData?.depositorIndustry,
          beneficiaryIndustries: formData?.beneficiaryIndustry,
          requiredCurrency: formData?.requiredCurrency,
          sourceCountries: formData?.sourceCountry,
          destinationCountries: formData?.destinationCountry,
          monthlyVolume: Number(formData?.monthlyTransactions),
          annuallyVolume: Number(formData?.annualVolume),
          fx: Number(formData?.fx),
          pepSanction: convertToBoolean(formData?.customerDueDiligence),
          amlPolicy: convertToBoolean(formData?.adverseMedia),
          businessId: businessId,
          foreignBankAccountOwnerships: formattedData,
        },
      })
    );
    if (CreateUSDAccount.rejected.match(action)) {
      onShowErrorAlert("", String(action.payload));
    }

    if (CreateUSDAccount.fulfilled.match(action)) {
      uploadBusinessDataToGoogleSheet();
      SendKlashaComplainceDocs();
    }
  };

  const handleFileChange = (key: string, file: File | null) => {
    setFiles((prevFiles) => ({
      ...prevFiles,
      [key]: file,
    }));
  };

  return (
    <DocumentContainer>
      <Loader isLoading={loading} />
      <Title>Upload your documents</Title>

      {mockBusinessData.map((item, index) => (
        <DocumentSection key={index}>
          <DocumentTitle>{item.title}</DocumentTitle>
          <Controller
            name={`files.${item.title}`}
            control={control}
            rules={{ required: "This field is required" }}
            render={({ field }) => (
              <DocumentUpload
                error={errors.files?.[item.title]?.message}
                onFileChange={(file: File | null) => {
                  field.onChange(file);
                  handleFileChange(item.title, file);
                }}
              />
            )}
          />
        </DocumentSection>
      ))}

      <DocumentSection>
        <DocumentTitle>KYC Director</DocumentTitle>
        <ul>
          <li>Government photo ID (Passport or ID Card)</li>
          <li>
            Proof of address document - utility bill or bank statement dated
            within the last 3 months (documents not in English or legible need
            to be translated and notarized)
          </li>
        </ul>
        <Controller
          name="files.KYC Director"
          control={control}
          rules={{ required: "This field is required" }}
          render={({ field }) => (
            <DocumentUpload
              error={errors.files?.["KYC Director"]?.message}
              onFileChange={(file: File | null) => {
                field.onChange(file);
                handleFileChange("KYC Director", file);
              }}
            />
          )}
        />
      </DocumentSection>

      {beneficiaries?.length ? (
        beneficiaries
          .filter(
            (data) => data.foreignBankAccountOwnershipType === "BENEFICIARY"
          )
          .slice(0, 2)
          .map((data, index) => (
            <DocumentSection key={index}>
              <DocumentTitle>KYC UBO {index + 1}</DocumentTitle>
              <ul>
                <li>Government photo ID (Passport or ID Card)</li>
                <li>
                  Proof of address document - utility bill or bank statement
                  dated within the last 3 months
                </li>
              </ul>
              <Controller
                name={`files.KYC UBO ${index + 1}`}
                control={control}
                rules={{ required: "This field is required" }}
                render={({ field }) => (
                  <DocumentUpload
                    error={errors.files?.[`KYC UBO ${index + 1}`]?.message}
                    onFileChange={(file: File | null) => {
                      field.onChange(file);
                      handleFileChange(`KYC UBO ${index + 1}`, file);
                    }}
                  />
                )}
              />
            </DocumentSection>
          ))
      ) : (
        <DocumentSection>
          <DocumentTitle>KYC UBO</DocumentTitle>
          <ul>
            <li>Government photo ID (Passport or ID Card)</li>
            <li>
              Proof of address document - utility bill or bank statement dated
              within the last 3 months
            </li>
          </ul>
          <Controller
            name="files.KYC UBO"
            control={control}
            rules={{ required: "This field is required" }}
            render={({ field }) => (
              <DocumentUpload
                error={errors.files?.["KYC UBO"]?.message}
                onFileChange={(file: File | null) => {
                  field.onChange(file);
                  handleFileChange("KYC UBO", file);
                }}
              />
            )}
          />
        </DocumentSection>
      )}

      <DocumentSection>
        <DocumentTitle>Register of Shareholders</DocumentTitle>
        <ul>
          <li>
            KYC required for any individual who holds more than 10% of the
            shares directly or indirectly
          </li>
          <li>Notarised ownership structure verifying UBOs, OR</li>
          <li>
            Register of shareholders for each layer to verify the ownership
            structure manually
          </li>
        </ul>
        <Controller
          name="files.Register of Shareholders"
          control={control}
          rules={{ required: "This field is required" }}
          render={({ field }) => (
            <DocumentUpload
              error={errors.files?.["Register of Shareholders"]?.message}
              onFileChange={(file: File | null) => {
                field.onChange(file);
                handleFileChange("Register of Shareholders", file);
              }}
            />
          )}
        />
      </DocumentSection>

      <DocumentSection>
        <DocumentTitle>Working capital - annual accounts</DocumentTitle>
        <ul>
          <li>Signed by either board member or Auditor</li>
        </ul>
        <Controller
          name="files.Working capital - annual accounts"
          control={control}
          rules={{ required: "This field is required" }}
          render={({ field }) => (
            <DocumentUpload
              error={
                errors.files?.["Working capital - annual accounts"]?.message
              }
              onFileChange={(file: File | null) => {
                field.onChange(file);
                handleFileChange("Working capital - annual accounts", file);
              }}
            />
          )}
        />
      </DocumentSection>

      <DocumentSection>
        <DocumentTitle>Corporate bank statement</DocumentTitle>
        <ul>
          <li>Dated within the last 3 months</li>
          <li>
            In the absence of a valid bank statement, a Letter of good standing
            by an accountant or suitably qualified person, confirming the
            financial status of the company, funding sources, and projected
            turnover for the next 12-month period
          </li>
        </ul>
        <Controller
          name="files.Corporate bank statement"
          control={control}
          rules={{ required: "This field is required" }}
          render={({ field }) => (
            <DocumentUpload
              error={errors.files?.["Corporate bank statement"]?.message}
              onFileChange={(file: File | null) => {
                field.onChange(file);
                handleFileChange("Corporate bank statement", file);
              }}
            />
          )}
        />
      </DocumentSection>

      <NotificationSection>
        <NotificationBox>
          <img src={NotificationIcon} alt="Notification Icon" />
          <ul>
            <li>
              Ongoing monitoring is required as per the 4th Money Laundering
              Regulations. Additional information may be required as the
              business relationship develops or if any changes in Directors or
              Ownership occur to ensure compliance with regulatory obligations.
              Documents must be certified within the last three months.
            </li>
            <li>
              Documents required to be certified within the last three months.
            </li>
          </ul>
        </NotificationBox>
      </NotificationSection>

      <Button
        theme="primary"
        label="Submit"
        fontSize="14px"
        onClick={handleSubmit(RequestUSDAccount)}
      />
    </DocumentContainer>
  );
};

export default Documents;
