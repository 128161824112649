import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import {
  Wrapper,
  Header,
  Inputs,
  InputBox,
  CheckboxText,
  CheckboxWrapper,
  ButtonsDiv,
} from "./style";
import { currencies } from "./utils";
import {
  countryOptions,
  sortCurrencyObjectByNameFunction,
} from "../../../../../utils/format";
import { Input } from "../../../../common/Input";
import CustomSelect from "../../../../common/CustomSelect";
import { Button } from "../../../../common/Button";
import { AccountModalProps, FormInputs } from "../types";
import ErrorMessage from "../../../../ErrorMessage";
import { getBankList } from "../../../../../pages/Settings/settingsAPI";
import { useAppSelector } from "../../../../../app/hooks";

const AccountModal = ({
  onSaveAccount,
  modalType,
  account,
}: AccountModalProps) => {
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm<FormInputs>({
    defaultValues: {
      country: account.country,
      currency: account.currency,
      bankName: account.bankName,
      accNum: account.accNum,
      accName: account.accName,
      swiftCode: account.swiftCode,
      iban: account.iban,
      routingNum: account.routingNumber,
      sortCode: account.sortCode,
    },
  });

  const onCancel = () => {
    reset(
      {
        country: null,
        currency: null,
        bankName: null,
        accNum: null,
        accName: null,
        swiftCode: null,
        iban: null,
        routingNum: null,
        sortCode: null,
      },
      {
        keepErrors: true,
        keepTouched: false,
      }
    );
  };

  const [hasAccountList, setHasAccountList] = useState(false);
  const [isNigerian, setIsNigerian] = useState(false);
  const [unformattedBankList, setUnformattedBankList] = useState([]);
  const [bankList, setBankList] = useState([]);
  const [currencyList, setCurrencyList] = useState(currencies);
  const [bankObj, setBankObj] = useState<any>({});
  const [bankCode, setBankCode] = useState<any>({});
  const { businessId } = useAppSelector(
    (state) => state.users,
);

  useEffect(() => {
    if (isNigerian) {
      setCurrencyList([{ label: "NGN", value: "NGN" }]);
    } else {
      setCurrencyList(currencies);
    }
  }, [isNigerian, currencies]);

  const accountListAvailability = async (country: string) => {
    const availableCountries = ["Nigeria", "Kenya", "Ghana"];
    const CountryToCodeMap = {
      Nigeria: "NG",
      Ghana: "GH",
      Kenya: "KE",
    };
    if (availableCountries.includes(country)) {
      setHasAccountList(true);
      const banks = await getBankList(CountryToCodeMap[country])
      setUnformattedBankList(banks);
      const formattedBanks = banks
        .map((bank) => ({
          value: bank.name,
          label: bank.name,
        }))
        .sort(sortCurrencyObjectByNameFunction);
      setBankList(formattedBanks);
    } else {
      setHasAccountList(false);
    }
  };
  const onCountryChange = (value) => {
    accountListAvailability(value);
    setIsNigerian(value === "Nigeria");
  };

  useEffect(() => {
    if (Object.keys(account).length > 1) {
      accountListAvailability(account.country);
    }
  }, [account]);

  const setSelectedBankObj = (bankName:string) => {
    for (const bank of unformattedBankList) {
      if (bank.name === bankName) {
        setBankObj(bank);
        setBankCode(bank.code);
        break;
      }
    }
  };
  const onBankChange = (value) => {
    setSelectedBankObj(value);
  };
  


  useEffect(() => {
    const uniqueBankNames = Array.from(new Set(unformattedBankList.map(bank => bank.name)));
    setBankList(uniqueBankNames);
  }, [unformattedBankList]);

  const onSubmit: SubmitHandler<FormInputs> = (data) => {
    const updatedData: FormInputs = { ...data, bankCode: bankCode ? bankCode : "", businessId: businessId };
    onSaveAccount(updatedData);
  };

  const formattedBankOptions = bankList?.map((bankName) => ({
    value: bankName,
    label: bankName,
  }));
  
  return (
    <Wrapper onSubmit={handleSubmit(onSubmit)}>
      <Header>{`${modalType === "add" ? "Add" : "Edit"} bank account`}</Header>
      <Inputs>
        <InputBox>
          <Controller
            name="country"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <CustomSelect
                options={countryOptions}
                $height="48px"
                $width="100%"
                field={field}
                placeholder="Country"
                onChange={onCountryChange}
                showSearch
              />
            )}
          />

          {errors.country?.type === "required" && (
            <ErrorMessage message="Field is required" />
          )}
        </InputBox>
        <InputBox>
          <Controller
            name="currency"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <CustomSelect
                options={currencyList}
                $height="48px"
                $width="100%"
                field={field}
                placeholder="Currency"
              />
            )}
          />
          
 
          {errors.currency?.type === "required" && (
            <ErrorMessage message="Field is required" />
          )}
        </InputBox>
        <InputBox>
          <Controller
            name="bankName"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => {
              return hasAccountList ? (
                <CustomSelect
                 showSearch
                 options={formattedBankOptions}
                 $height="48px"
                  $width="100%"
                  field={field}
                  placeholder="Bank name"
                  onChange={onBankChange}
                  defaultValue={account.bankName ? account.bankName : null}
                />
              ) : (
                <Input
                  type="text"
                  label="Bank name"
                  value={field.value}
                  onChange={field.onChange}
                  labelColor="#FFFFFF"
                  defaultValue={account.bankName ? account.bankName : null}
                />
              );
            }}
          />
          {errors.bankName?.type === "required" && (
            <ErrorMessage message="Field is required" />
          )}
        </InputBox>
        <InputBox>
          <Controller
            name="accName"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <Input
                type="text"
                label="Account name"
                value={field.value}
                onChange={field.onChange}               
                 labelColor="#FFFFFF"
              />
            )}
          />
          {errors.accName?.type === "required" && (
            <ErrorMessage message="Field is required" />
          )}
        </InputBox>
        <InputBox>
          <Controller
            name="accNum"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <Input
                type="number"
                label="Account number"
                value={field.value}
                onChange={field.onChange}
                labelColor="#FFFFFF"
              />
            )}
          />
          {errors.accNum?.type === "required" && (
            <ErrorMessage message="Field is required" />
          )}
        </InputBox>
        <InputBox>
          <Controller
            name="iban"
            control={control}
            render={({ field }) => (
              <Input
                type="text"
                label="IBAN"
                value={field.value}
                onChange={field.onChange}
                labelColor="#FFFFFF"
              />
            )}
          />
        </InputBox>
        <InputBox>
          <Controller
            name="swiftCode"
            control={control}
            render={({ field }) => (
              <Input
                type="text"
                label="Swift code"
                value={field.value}
                onChange={field.onChange}
                labelColor="#FFFFFF"
              />
            )}
          />
        </InputBox>
        <InputBox>
          <Controller
            name="routingNum"
            control={control}
            render={({ field }) => (
              <Input
                type="number"
                label="Routing number"
                value={field.value}
                onChange={field.onChange}
                labelColor="#FFFFFF"
              />
            )}
          />
        </InputBox>
        <InputBox>
          <Controller
            name="sortCode"
            control={control}
            render={({ field }) => (
              <Input
                type="number"
                label="Sort code"
                value={field.value}
                onChange={field.onChange}
                labelColor="#FFFFFF"
              />
            )}
          />
        </InputBox>

        <Controller
          name="isPrimary"
          control={control}
          render={({ field }) => (
            <CheckboxWrapper {...field}>
              <CheckboxText>Make this my primary bank account</CheckboxText>
            </CheckboxWrapper>
          )}
        />
      </Inputs>
      <ButtonsDiv>
        <Button
          label="Save"
          type="submit"
          theme="secondary"
          height="48px"
          fontSize="14px"
        />
        <Button
          label="Cancel"
          type="button"
          theme="alternate"
          height="48px"
          fontSize="14px"
          onClick={onCancel}
        />
      </ButtonsDiv>
    </Wrapper>
  );
};

export default AccountModal;
