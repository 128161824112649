import React, { useState } from "react";
import CustomStepper from "../../../../components/common/CustomStepper";
import { Wrapper } from "./styles";
import Amount from "./Funds/Amount";
import Review from "./Funds/Review";
import Beneficiary from "./Beneficiary";

interface Stage {
  stage: number;
  title: string;
  toolTip?: boolean;
}

const stages: Stage[] = [
  {
    stage: 1,
    title: "Amount",
    toolTip: true,
  },
  {
    stage: 2,
    title: "Beneficiary",
  },
  {
    stage: 3,
    title: "Review",
  },
];

const Withdrawal: React.FC = () => {
  const [step, setStep] = useState<number>(1);

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return <Amount setStep={setStep} />;
      case 2:
        return <Beneficiary setStep={setStep} />;
      case 3:
        return <Review onGoBack={setStep} />;
      default:
        return null;
    }
  };

  return (
    <Wrapper>
      <CustomStepper stages={stages} step={step} setStep={setStep} />
      {renderStepContent()}
    </Wrapper>
  );
};

export default Withdrawal;
