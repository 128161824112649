import axios from "axios";
import { defaultApi } from "../app/api";

const headers = {
  "Content-Type": "application/json",
  "x-fe-token": process.env.REACT_APP_DASHBOARD_ACCESS_TOKEN,
};

export async function sendOtpToMerchant(credentials: {
  email: string;
  password: string;
}) {
  return await defaultApi.post(
    "auth/account/one/time/password",
    JSON.stringify(credentials),
    { headers }
  );
}

export async function verifyMerchantOtp({
  otp,
  email,
}: {
  otp: string;
  email: string;
}) {
  const res = await defaultApi.get(
    `auth/account/merchant/login/${otp}/${email}`,
    { headers }
  );
  return res;
}

export async function signupUser(payload: {
  firstName: string;
  lastName: string;
  email: string;
  businessName: string;
  businessCountry: string;
  username: string;
  password: string;
  matchingPassword: string;
  userType: string;
  regUrl: string;
  publicReferrer: string;
}) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}auth/user/registration/v2`,
    payload,
    { headers }
  );
  return response;
}

export async function confirmRegistration({ id }: { id?: string }) {
  if (id) {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}auth/user/confirmregistration/${id}`,
      { headers }
    );
    return response;
  }
}

export async function forgotPassword({
  email,
  domain,
}: {
  email: string;
  domain: string;
}) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}auth/user/resetPassword/${email}`,
    { email, domain },
    { headers }
  );
  return response;
}

export async function changePassword({
  password,
  token,
}: {
  password: string;
  token: string;
}) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}auth/user/savePassword`,
    { oldPassword: password, newPassword: password, token },
    { headers }
  );
  return response;
}

export async function resendActivation({
  email,
  url,
}: {
  email: string;
  url: string;
}) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}auth/user/resendToken`,
    { email, url },
    { headers }
  );
  return response;
}
