import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
`;

export const OptionLabel = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

export const FlagImage = styled.img`
  width: 20px;
  height: 15px;
  margin-right: 10px;
`;
