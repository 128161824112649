import React, { useContext, useEffect, useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { Title, Inputs, InputBox, CountryBox } from "./style";
import { Input } from "../../../../../../components/common/Input";
import { Button } from "../../../../../../components/common/Button";
import { InputPhone } from "../../../../../../components/common/InputPhone";
import CustomSelect from "../../../../../../components/common/CustomSelect";
import { countryOptions } from "../../../../../../utils/format";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { NetworkErrorAlertContext } from "../../../../../../context/NetworkErrorAlert";
import { SuccessAlertContext } from "../../../../../../context/SuccessAlertContext";
import { addBeneficiary } from "../../../../redux/slice";
import { encryptData } from "../../../../../../utils/ecryptionData";
import { getCountryCode } from "../../../../utils";

type FormInputs = {
  beneficiaryName: string;
  businessName: string;
  country: string;
  currency: string;
  swiftCode: string;
  routingNumber: string;
  postCode: string;
  bankName: string;
  bankAddress: string;
  accNum: string;
  phoneNumber: string;
  beneficiaryEmail: string;
  iban: string;
  bic: string;
  beneficiaryTown: string;
  beneficiaryType: string;
  industry: string;
  industryDetails: string;
  street: string;
  town: string;
};

const BeneficiaryModal = ({
  setShowNewModal,
  showTitle,
  handleNext,
  getBeneficiaries,
}: {
  showTitle: boolean;
  setShowNewModal?: (arg: boolean) => void;
  onSuccess?: () => void;
  handleNext?: (data) => void;
  getBeneficiaries: (arg: number) => void;
}) => {
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<FormInputs>();
  const { onShowAlert: onShowErrorAlert } = useContext(
    NetworkErrorAlertContext
  );
  const { onShowAlert: onShowSuccessAlert } = useContext(SuccessAlertContext);
  const savedBeneficiaries = useAppSelector(
    (state) => state?.formData?.beneficiaries || []
  );
  const [countryCode, setCountryCode] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const { businessId } = useAppSelector((state) => state.users || {});

  const selectedCountry = watch("country");
  useEffect(() => {
    if (selectedCountry) {
      const code = getCountryCode(selectedCountry);
      setCountryCode(code);
    }
  }, [selectedCountry]);

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const beneficiaryType = [
    { label: "Individual", value: "Individual" },
    { label: "Company", value: "Company" },
  ];

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    const countryName = data.country;
    const countryCode = getCountryCode(countryName) || null;
    if (!countryCode) {
      onShowErrorAlert("error", "Invalid country code");
      return;
    }
    const payload: {
      name: string;
      beneficiaryName: string;
      country: string;
      bankName: string;
      phone: string;
      email: string;
      business: number | null;
      bankAddress: string;
      route: string;
      currency: string;
      accountCurrency: string;
      beneficiaryType: string;
      countryCode: any;
      postCode: string;
      routingNumber?: string;
      accountNumber?: string;
      street: string;
      town: string;
      bic?: string;
      iban?: string;
      swiftCode?: string;
    } = {
      name: data.businessName,
      beneficiaryName: data.beneficiaryName,
      country: data.country,
      bankName: data.bankName,
      phone: data.phoneNumber,
      email: data.beneficiaryEmail,
      business: businessId,
      bankAddress: data.bankAddress,
      route: "BAAS",
      currency: data.currency,
      accountCurrency: "USD",
      beneficiaryType: data.beneficiaryType,
      countryCode: countryCode,
      postCode: data.postCode,
      street: data.street,
      town: data.town,
    };

    switch (countryCode) {
      case "US":
        payload.routingNumber = data.routingNumber;
        payload.accountNumber = data.accNum;
        payload.iban = data.iban;
        payload.swiftCode;

        break;
      case "CA":
        payload.bic = data.bic;
        payload.routingNumber = data.routingNumber;
        payload.accountNumber = data.accNum;
        break;
      case "AU":
        payload.bic = data.bic;
        payload.routingNumber = data.routingNumber;
        payload.accountNumber = data.accNum;
        break;
      case "IN":
        payload.bic = data.bic;
        payload.routingNumber = data.routingNumber;
        payload.accountNumber = data.accNum;
        break;
      case "GB":
        payload.routingNumber = data.routingNumber;
        payload.accountNumber = data.accNum;
        break;
      default:
        if (["AE", "MX", "NZ"].includes(countryCode)) {
          payload.bic = data.bic;
          payload.accountNumber = data.accNum;
        } else {
          payload.bic = data.bic;
          payload.iban = data.iban;
          payload.accountNumber = data.accNum;
        }
        break;
    }

    const encryptedPayload = {
      message: encryptData(payload),
    };

    const action = await dispatch(addBeneficiary({ data: encryptedPayload }));

    if (addBeneficiary.fulfilled.match(action)) {
      if (!savedBeneficiaries.length) {
        handleNext && handleNext(3);
      }

      onShowSuccessAlert("Beneficiary created successfully");

      if (setShowNewModal) {
        setShowNewModal(false);
      }
      getBeneficiaries(businessId);
    }

    if (addBeneficiary.rejected.match(action)) {
      onShowErrorAlert("error", String(action.payload));
    }
  };

  const BeneficiaryCurrencies = [
    { label: "GBP", value: "GBP" },
    { label: "EUR", value: "EUR" },
    { label: "USD", value: "USD" },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {showTitle && <Title>Create new beneficiary</Title>}

      <Inputs showTitle={showTitle}>
        <InputBox>
          <Controller
            name="beneficiaryName"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                type="text"
                label="Beneficiary name"
                value={field.value}
                onChange={field.onChange}
                hasError={errors?.beneficiaryName?.type === "required"}
              />
            )}
          />
          {errors.beneficiaryName?.type === "required" && (
            <span>Field is required</span>
          )}
        </InputBox>
        <InputBox>
          <Controller
            name="businessName"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                type="text"
                label="Business name"
                value={field.value}
                onChange={field.onChange}
                hasError={errors?.businessName?.type === "required"}
              />
            )}
          />
          {errors.businessName?.type === "required" && (
            <span>Field is required</span>
          )}
        </InputBox>
        <CountryBox>
          <Controller
            name="beneficiaryType"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomSelect
                $width="100%"
                placeholder="Beneficiary type"
                value={field.value}
                onChange={field.onChange}
                options={beneficiaryType}
              />
            )}
          />
          {errors.beneficiaryType?.type === "required" && (
            <span>Field is required</span>
          )}
        </CountryBox>
        <InputBox>
          <Controller
            name="phoneNumber"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <InputPhone
                label="Phone number"
                field={field}
                hasError={errors?.phoneNumber?.type === "required"}
              />
            )}
          />
          {errors.phoneNumber?.type === "required" && (
            <span>Field is required</span>
          )}
        </InputBox>
        <InputBox>
          <Controller
            name="beneficiaryEmail"
            control={control}
            rules={{
              required: true,
              pattern: emailRegex,
            }}
            render={({ field }) => (
              <Input
                type="text"
                label="Beneficiary email"
                value={field.value}
                onChange={field.onChange}
                hasError={errors?.beneficiaryEmail?.type === "required"}
              />
            )}
          />
          {errors.beneficiaryEmail?.type === "required" && (
            <span>Field is required</span>
          )}
          {errors.beneficiaryEmail?.type === "pattern" && (
            <span>Invalid email format</span>
          )}
        </InputBox>
        <InputBox>
          <Controller
            name="street"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                type="text"
                label="Beneficiary street"
                value={field.value}
                onChange={field.onChange}
                hasError={errors?.street?.type === "required"}
              />
            )}
          />
          {errors.street?.type === "required" && <span>Field is required</span>}
        </InputBox>
        <InputBox>
          <Controller
            name="town"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                type="text"
                label="Beneficiary town"
                value={field.value}
                onChange={field.onChange}
                hasError={errors?.town?.type === "required"}
              />
            )}
          />
          {errors.town?.type === "required" && <span>Field is required</span>}
        </InputBox>
        <CountryBox>
          <Controller
            name="country"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomSelect
                placeholder="Country"
                options={countryOptions}
                $width="100%"
                value={field.value}
                onChange={field.onChange}
                showSearch
              />
            )}
          />
          {errors.country?.type === "required" && <p>Field is required</p>}
        </CountryBox>
        <CountryBox>
          <Controller
            name="currency"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomSelect
                placeholder="Currency"
                options={BeneficiaryCurrencies}
                value={field.value}
                onChange={field.onChange}
                $width="100%"
              />
            )}
          />
          {errors.currency?.type === "required" && (
            <span>Field is required</span>
          )}
        </CountryBox>
        {countryCode && !["AE", "MX", "NZ"].includes(countryCode) && (
          <InputBox>
            <Controller
              name="iban"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  type="text"
                  label="IBAN"
                  value={field.value}
                  onChange={field.onChange}
                  hasError={errors?.iban?.type === "required"}
                />
              )}
            />
            {errors.iban?.type === "required" && <span>Field is required</span>}
          </InputBox>
        )}
        {countryCode &&
          (["CA", "AU", "IN", "GB"].includes(countryCode) ||
            ["AE", "MX", "NZ"].includes(countryCode)) && (
            <InputBox>
              <Controller
                name="bic"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    type="text"
                    label="BIC"
                    value={field.value}
                    onChange={field.onChange}
                    hasError={errors?.bic?.type === "required"}
                  />
                )}
              />
              {errors.bic?.type === "required" && (
                <span>Field is required</span>
              )}
            </InputBox>
          )}
        <InputBox>
          <Controller
            name="swiftCode"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                type="text"
                label="Swift code"
                value={field.value}
                onChange={field.onChange}
                hasError={errors?.swiftCode?.type === "required"}
              />
            )}
          />
          {errors.swiftCode?.type === "required" && (
            <span>Field is required</span>
          )}
        </InputBox>
        <InputBox>
          <Controller
            name="bankName"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                type="text"
                label="Bank name"
                value={field.value}
                onChange={field.onChange}
                hasError={errors?.bankName?.type === "required"}
              />
            )}
          />
          {errors.bankName?.type === "required" && (
            <span>Field is required</span>
          )}
        </InputBox>
        <InputBox>
          <Controller
            name="bankAddress"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                type="text"
                label="Bank address"
                value={field.value}
                onChange={field.onChange}
                hasError={errors?.bankAddress?.type === "required"}
              />
            )}
          />
          {errors.bankAddress?.type === "required" && (
            <span>Field is required</span>
          )}
        </InputBox>
        {countryCode && (
          <InputBox>
            <Controller
              name="accNum"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  type="text"
                  label="Account number"
                  value={field.value}
                  onChange={field.onChange}
                  hasError={errors?.accNum?.type === "required"}
                />
              )}
            />
            {errors.accNum?.type === "required" && (
              <span>Field is required</span>
            )}
          </InputBox>
        )}
        {(countryCode === "US" ||
          countryCode === "CA" ||
          countryCode === "AU" ||
          countryCode === "IN" ||
          countryCode === "GB") && (
          <>
            <InputBox>
              <Controller
                name="routingNumber"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    type="text"
                    label="Routing number"
                    value={field.value}
                    onChange={field.onChange}
                    hasError={errors?.routingNumber?.type === "required"}
                  />
                )}
              />
              {errors.routingNumber?.type === "required" && (
                <span>Field is required</span>
              )}
            </InputBox>
            <InputBox>
              <Controller
                name="postCode"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    type="text"
                    label="Post code"
                    value={field.value}
                    onChange={field.onChange}
                    hasError={errors?.routingNumber?.type === "required"}
                  />
                )}
              />
              {errors.postCode?.type === "required" && (
                <span>Field is required</span>
              )}
            </InputBox>
          </>
        )}
        <Button
          label="Continue"
          theme={showTitle ? "secondary" : "primary"}
          height={showTitle ? "40px" : "48px"}
          fontSize="12px"
          type="submit"
        />{" "}
      </Inputs>
    </form>
  );
};

export default BeneficiaryModal;
