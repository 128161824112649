import React, { useContext, useEffect } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { Button } from "../../../../../../components/common/Button";
import "dayjs/locale/en";
import {
  ItemWrapper,
  ContentWrapper,
  Error,
  InputWrapper,
  RWrapper,
} from "../styles";
import CustomRadio from "../../../../../../components/common/RadioInput";
import { savedCNYData, updateFormData } from "../../../../redux/formDataSlice";
import CustomSelect from "../../../../../../components/common/CustomSelect";
import { Input } from "../../../../../../components/common/Input";
import CustomDatePicker from "../../../../../../components/common/CustomDateRangePicker/CustomDatePicker";
import CountrySelect from "../../../../../../components/common/CountrySelect";
import { CNYIDTypes } from "../../../../static";
import { DocumentUpload } from "../../../../components/DocumentUpload";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { uploadCNYMerchantFile } from "../../../../redux/slice";
import { NetworkErrorAlertContext } from "../../../../../../context/NetworkErrorAlert";
import Loader from "../../../../../../components/common/Loader";
import dayjs from "dayjs";
import { format } from "date-fns/format";
import { formatDate } from "../../../../../../utils/dateUtils";

type Props = {
  handleNext?: () => void;
  handlePrevious?: () => void;
};

type FormInputs = {
  senderFirstName: string;
  senderLastName: string;
  senderID: string;
  senderIdNumber: string;
  senderBirthDate: string;
  senderStreet: string;
  senderCity: string;
  senderState: string;
  senderCountry: string;
  senderPostcode: string;
  senderIdentity: string;
  senderType: string;
  senderDocument?: string;
};

const Sender = ({ handleNext, handlePrevious }: Props) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm<FormInputs>();

  const dispatch = useAppDispatch();
  const { onShowAlert: onShowErrorAlert } = useContext(
    NetworkErrorAlertContext,
  );

  const { keys } = useAppSelector((state) => state.settings);
  const { loading } = useAppSelector((state) => state?.wallets || {});
  const getSavedData = useAppSelector((state) => state.formData.cnyData);

  const formatAndDispatch = (data: FormInputs) => {
    data.senderBirthDate = formatDate(data.senderBirthDate);
    dispatch(savedCNYData(data));
  };
  const checkSender = watch("senderType");
  const ultimateSender = watch("senderIdentity");

  const publicKey = keys?.data?.publicKey;

  const handleFileChange = async (title: string, file: File | null) => {
    if (!file) return;

    const action = await dispatch(
      uploadCNYMerchantFile({
        file,
        purpose: "Business ID",
        attachmentType: "COMMERCIAL_DOCUMENTS",
        xAuthToken: publicKey,
      }),
    );

    if (uploadCNYMerchantFile.rejected.match(action)) {
      onShowErrorAlert("error", action.payload as string);
    }
    return;
  };

  const onSubmit: SubmitHandler<FormInputs> = (data) => {
    const formattedDate = dayjs(data.senderBirthDate);
    const formattedDateString = formattedDate.format("YYYY-MM-DD");
    data.senderBirthDate = formattedDateString;
    const senderAddress = {
      city: data.senderCity,
      streetAddress: data.senderStreet,
      countryCode: data.senderCountry,
      postcode: data.senderPostcode,
      state: data.senderState,
    };

    const updatedData = {
      ...data,
      senderAddress,
    };

    dispatch(savedCNYData(updatedData));
    formatAndDispatch(updatedData);
    handleNext?.();
  };

  useEffect(() => {
    if (getSavedData) {
      Object.keys(getSavedData).forEach((key) => {
        if (key === "senderBirthDate") {
          setValue(key as keyof FormInputs, null);
        } else {
          setValue(
            key as keyof FormInputs,
            getSavedData[key as keyof FormInputs],
          );
        }
      });
    }
  }, [getSavedData, setValue]);

  return (
    <ContentWrapper>
      <Loader isLoading={loading} />
      <InputWrapper>
        <Controller
          name="senderIdentity"
          control={control}
          rules={{ required: "This field is required" }}
          render={({ field }) => (
            <ItemWrapper>
              {["Yes", "No"].map((option) => (
                <RWrapper key={option}>
                  <CustomRadio
                    label={option}
                    value={option}
                    onClick={() => field.onChange(option)}
                    selected={field.value === option}
                  />
                </RWrapper>
              ))}
            </ItemWrapper>
          )}
        />
        {<Error>{errors?.senderIdentity?.message}</Error>}
      </InputWrapper>

      {ultimateSender === "No" && (
        <InputWrapper>
          <Controller
            name="senderType"
            control={control}
            rules={{ required: "Sender type is required" }}
            render={({ field }) => (
              <CustomSelect
                value={field.value}
                options={[
                  { label: "Individual", value: "individual" },
                  { label: "Business", value: "company" },
                ]}
                onChange={field.onChange}
                $width="100%"
                placeholder="Sender type"
              />
            )}
          />
          {<Error>{errors?.senderType?.message}</Error>}
        </InputWrapper>
      )}

      <ItemWrapper>
        <InputWrapper>
          <Controller
            name="senderFirstName"
            control={control}
            rules={{ required: "First name is required" }}
            render={({ field }) => (
              <Input
                type="text"
                label="First name"
                value={field.value}
                onChange={field.onChange}
                hasError={!!errors.senderFirstName}
                labelColor="#FFFFFF"
              />
            )}
          />
          {<Error>{errors?.senderFirstName?.message}</Error>}
        </InputWrapper>

        <InputWrapper>
          <Controller
            name="senderLastName"
            control={control}
            rules={{ required: "Last name is required" }}
            render={({ field }) => (
              <Input
                type="text"
                label="Last name"
                value={field.value}
                onChange={field.onChange}
                hasError={!!errors.senderLastName}
                labelColor="#FFFFFF"
              />
            )}
          />
          {<Error>{errors?.senderLastName?.message}</Error>}
        </InputWrapper>
      </ItemWrapper>

      <ItemWrapper>
        <InputWrapper>
          <Controller
            name="senderID"
            control={control}
            rules={{ required: "ID type is required" }}
            render={({ field }) => (
              <CustomSelect
                value={field.value}
                options={CNYIDTypes}
                onChange={field.onChange}
                $width="100%"
                placeholder="ID Type"
              />
            )}
          />
          {<Error>{errors?.senderID?.message}</Error>}
        </InputWrapper>
        <InputWrapper>
          <Controller
            name="senderIdNumber"
            control={control}
            rules={{ required: "ID number is required" }}
            render={({ field }) => (
              <Input
                type="text"
                label="ID Number"
                value={field.value}
                onChange={field.onChange}
                hasError={!!errors.senderIdNumber}
              />
            )}
          />
          {<Error>{errors?.senderIdNumber?.message}</Error>}
        </InputWrapper>
      </ItemWrapper>

      <InputWrapper>
        <Controller
          name="senderBirthDate"
          control={control}
          rules={{ required: "Birth date is required" }}
          render={({ field }) => (
            <CustomDatePicker
              value={field.value}
              onChange={field.onChange}
              placeholder="Birth Date"
            />
          )}
        />
        {<Error>{errors?.senderBirthDate?.message}</Error>}
      </InputWrapper>

      <InputWrapper>
        <Controller
          name="senderStreet"
          control={control}
          rules={{ required: "Street address is required" }}
          render={({ field }) => (
            <Input
              type="text"
              label="Street address"
              value={field.value}
              onChange={field.onChange}
              hasError={!!errors.senderStreet}
            />
          )}
        />
        {<Error>{errors?.senderStreet?.message}</Error>}
      </InputWrapper>

      <ItemWrapper>
        <InputWrapper>
          <Controller
            name="senderCity"
            control={control}
            rules={{ required: "City is required" }}
            render={({ field }) => (
              <Input
                type="text"
                label="City"
                value={field.value}
                onChange={field.onChange}
                hasError={!!errors.senderCity}
              />
            )}
          />
          {<Error>{errors?.senderCity?.message}</Error>}
        </InputWrapper>

        <InputWrapper>
          <Controller
            name="senderState"
            control={control}
            rules={{ required: "State is required" }}
            render={({ field }) => (
              <Input
                type="text"
                label="State"
                value={field.value}
                onChange={field.onChange}
                hasError={!!errors.senderState}
              />
            )}
          />
          {<Error>{errors?.senderState?.message}</Error>}
        </InputWrapper>
      </ItemWrapper>

      <ItemWrapper>
        <InputWrapper>
          <Controller
            name="senderCountry"
            control={control}
            rules={{ required: "Country is required" }}
            render={({ field }) => (
              <InputWrapper>
                <CountrySelect
                  value={field.value}
                  onChange={field.onChange}
                  allCountries={true}
                  placeholder="Country"
                />
              </InputWrapper>
            )}
          />
          {<Error>{errors?.senderCountry?.message}</Error>}
        </InputWrapper>

        <InputWrapper>
          <Controller
            name="senderPostcode"
            control={control}
            rules={{ required: "Postcode is required" }}
            render={({ field }) => (
              <Input
                type="text"
                label="Postcode"
                value={field.value}
                onChange={field.onChange}
                hasError={!!errors.senderPostcode}
              />
            )}
          />
          {<Error>{errors?.senderPostcode?.message}</Error>}
        </InputWrapper>
      </ItemWrapper>

      {checkSender === "company" && (
        <InputWrapper>
          <h1>Business ID</h1>
          <p>This should be the business registration certificate.</p>
          <Controller
            name="senderDocument"
            control={control}
            rules={{
              required: "Please upload a document",
              validate: (file) => file || "Uploaded file must be valid",
            }}
            render={({ field }) => (
              <DocumentUpload
                showExtra
                onFileChange={(file: File | null) => {
                  field.onChange(file);
                  handleFileChange("Working capital - annual accounts", file);
                }}
              />
            )}
          />
          {<Error>{errors?.senderDocument?.message}</Error>}
        </InputWrapper>
      )}

      <ItemWrapper>
        <Button
          onClick={handlePrevious}
          theme="alternate"
          height="48px"
          width="203px"
        >
          Back
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          label="Continue"
          height="48px"
        />
      </ItemWrapper>
    </ContentWrapper>
  );
};

export default Sender;
