import React, { ReactElement, useContext, useEffect } from "react";
import {
  BodyDiv,
  LeftDiv,
  RightDiv,
  TopDiv,
  Wrapper,
  Container,
  ImgContainer,
} from "./style";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import NetworkErrorAlert from "../common/alerts/NetworkErrorAlert";
import { NetworkErrorAlertContext } from "../../context/NetworkErrorAlert";

interface AuthWrapperProps {
  children: ReactElement;
}

export const AuthWrapper = ({ children }: AuthWrapperProps) => {
  const { showAlert, alertMessage } = useContext(NetworkErrorAlertContext);

  useEffect(() => {
    document.body.classList.add("login-page");
    return () => {
      document.body.classList.remove("login-page");
    };
  }, []);

  return (
    <Container>
      <Wrapper className="wrapper">
        <LeftDiv>
          {showAlert && <NetworkErrorAlert message={alertMessage} />}

          <TopDiv>
            <Logo />
          </TopDiv>
          <BodyDiv>{children}</BodyDiv>
        </LeftDiv>
        <RightDiv>
          <p>Accept and send payments seamlessly</p>
          <ImgContainer>
            <img
              src="https://assets.klasha.com/merchant-images/Dashboard_3.webp"
              alt="klasha dashboard"
            />
          </ImgContainer>
          {/*  */}
        </RightDiv>
      </Wrapper>
    </Container>
  );
};
