import React from "react";
import { Controller } from "react-hook-form";
import { ErrorText, FormWrapper } from "./styles";
import { Input } from "../common/Input";

type FormFieldProps = {
  name: string;
  control: any;
  label?: string;
  defaultValue?: any;
  type?: string;
  value?: string;
  errors?: any;
  labelColor?: string;
  disabled?: boolean;
  required?: boolean;
  rules?: any;
};

const FormField = ({
  name,
  control,
  defaultValue,
  label,
  type = "text",
  value,
  errors,
  labelColor = "#FFFFFF",
  disabled = false,
  required = false,
  rules = {},
}: FormFieldProps) => {
  const combinedRules = {
    ...(required ? { required: "Field is required" } : {}),
    ...rules,
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={combinedRules}
      render={({ field }) => (
        <FormWrapper>
          <Input
            label={label}
            value={field.value || value}
            onChange={field.onChange}
            hasError={!!errors?.[name]}
            labelColor={labelColor}
            disabled={disabled}
          />
          {errors?.[name] && (
            <ErrorText>
              <span>{errors[name].message}</span>
            </ErrorText>
          )}
        </FormWrapper>
      )}
    />
  );
};

export default FormField;
