import React, { useState } from "react";
import {
  FilterContainer,
  Right,
  Wrapper,
  FilterBox,
  FilterBoxFooter,
  FilterBoxItem,
  FilterBoxItemTitle,
  FilterButton,
  FilterFooterApplyButton,
  FilterFooterResetButton,
} from "./style";
import { ReactComponent as FilterIcon } from "../../../../assets/icons/filter.svg";
import CustomDateRangePicker from "../../../../components/common/CustomDateRangePicker";
import CustomSelectTwo from "../../../../components/common/CustomSelect/CustomSelectTwo";
import { RangePickerProps } from "antd/es/date-picker";
import dayjs from "dayjs";
import Export from "../../../../components/common/Export";
import Search from "../../../../components/common/Search";
import ResponsivePopover from "./ResponsivePopover";

const statusOptions = [
  { value: "0", label: "Successful" },
  { value: "1", label: "Pending" },
  { value: "2", label: "Failed" },
  { value: "3", label: "Cancelled" },
  { value: "4", label: "Reversed" },
];

const FilterContent = ({
  onStatusChange,
  onDateChange,
  onFilterButtonClick,
  onResetButtonClick,
}: {
  onFilterButtonClick: () => void;
  onResetButtonClick: () => void;
  onStatusChange: (val) => void;
  onDateChange: (val, val2) => void;
}) => {
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [selectedDate, setSelectedDate] = useState([]);
  const onResetClick = () => {
    onResetButtonClick();
    setSelectedMethod(null);
    setSelectedDate([]);
  };
  const onMethodSelectChange = (value) => {
    onStatusChange(value);
    setSelectedMethod(value);
  };

  const onDateRangeChange = (dates, dateString) => {
    onDateChange(dates, dateString);
    setSelectedDate(dates);
  };
  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days before today and today
    return current && current > dayjs().endOf("day");
  };
  return (
    <FilterBox>
      <FilterBoxItem>
        <FilterBoxItemTitle>Status</FilterBoxItemTitle>
        <CustomSelectTwo
          options={statusOptions}
          value={selectedMethod}
          onChange={onMethodSelectChange}
        />
      </FilterBoxItem>
      <FilterBoxItem>
        <FilterBoxItemTitle>Date range</FilterBoxItemTitle>
        <CustomDateRangePicker
          value={selectedDate}
          onChange={onDateRangeChange}
          disabledDate={disabledDate}
        />
      </FilterBoxItem>
      <FilterBoxFooter>
        <FilterFooterApplyButton onClick={onFilterButtonClick}>
          Filter
        </FilterFooterApplyButton>
        <FilterFooterResetButton onClick={onResetClick}>
          Reset
        </FilterFooterResetButton>
      </FilterBoxFooter>
    </FilterBox>
  );
};

interface TitleFilterProps {
  onStatusChange?: (val) => void;
  onDateChange?: (val, val2) => void;
  onSearchChange?: (val) => void;
  onXLSButtonClick?: () => void;
  onCSVButtonClick?: () => void;
  onFilterButtonClick?: () => void;
  onResetButtonClick?: () => void;
  onSelectChange?: any;
  select?: string;
  search: string;
  setSearch: (val: string) => void;
}
const TableTools = ({
  onXLSButtonClick,
  onCSVButtonClick,
  onResetButtonClick,
  onFilterButtonClick,
  onDateChange,
  onStatusChange,
  onSelectChange,
  select,
  search,
  setSearch,
}: TitleFilterProps) => {
  return (
    <Wrapper $show={true}>
      <FilterContainer>
        <Search value={search} onChange={(e) => setSearch(e.target.value)} />

        <Right>
          <ResponsivePopover
            content={
              <FilterContent
                onStatusChange={onStatusChange}
                onDateChange={onDateChange}
                onFilterButtonClick={onFilterButtonClick}
                onResetButtonClick={onResetButtonClick}
              />
            }
          >
            <FilterButton>
              <span>Filter</span>
              <FilterIcon />
            </FilterButton>
          </ResponsivePopover>

          <Export
            downloadCSV={onCSVButtonClick}
            downloadXLS={onXLSButtonClick}
          />
        </Right>
      </FilterContainer>
    </Wrapper>
  );
};

export default TableTools;
