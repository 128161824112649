import { BusinessTitle, BusinessText, BtnWrapper } from "../styles";
import FormSection from "../components/FormSection";
import { useForm, Controller } from "react-hook-form";
import CountrySelect from "../../../components/common/CountrySelect";
import { Input } from "../../../components/common/Input";
import { Button } from "../../../components/common/Button";
import { useContext } from "react";
import { NetworkErrorAlertContext } from "../../../context/NetworkErrorAlert";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { addSettlementDetails } from "../redux/actions";
import Loader from "../../../components/common/Loader";
import { SettlementTypes } from "../Types";
import { SettlementCurrencySelector } from "../components/SettlementCurrencySelector";


type Props = {
  handleNext: () => void;
  handlePrevious: () => void;
};

const SettlementDetails = ({ handleNext, handlePrevious }: Props) => {
  const { onShowAlert: onShowErrorAlert } =
    useContext(NetworkErrorAlertContext) || {};
  const { businessId } = useAppSelector((state) => state.users);

  const dispatch = useAppDispatch();

  const { loading } = useAppSelector((state) => state.onboarding || {});

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isValid },
    setValue
  } = useForm<SettlementTypes>({ mode: "onChange" });

  const onSubmit = async (data: SettlementTypes) => {
    const payload = {
      businessId: businessId,
      currency: data.currency,
      ...data,
    };
    const action = await dispatch(addSettlementDetails(payload));
    if (addSettlementDetails.fulfilled.match(action)) {
      handleNext();
    } else if (addSettlementDetails.rejected.match(action)) {
      onShowErrorAlert("", String(action.payload));
    }
  };

  const selectedCountry = watch("country");

  const renderCommonFields = () => (
    <>
      <FormSection>
        <Controller
          name="accountNumber"
          control={control}
          rules={{ required: "Account number is required" }}
          render={({ field }) => (
            <Input
              label="Account number"
              value={field.value}
              onChange={field.onChange}
              hasError={!!errors.accountNumber}
            />
          )}
        />
        {errors.accountNumber && (
          <span className="error">{errors.accountNumber.message}</span>
        )}
      </FormSection>

      <FormSection>
        <Controller
          name="bankName"
          control={control}
          rules={{ required: "Bank name is required" }}
          render={({ field }) => (
            <Input
              label="Bank name"
              value={field.value}
              onChange={field.onChange}
              hasError={!!errors.bankName}
            />
          )}
        />
        {errors.bankName && (
          <span className="error">{errors.bankName.message}</span>
        )}
      </FormSection>
    </>
  );

  const renderAccountNameField = () => (
    <FormSection>
      <Controller
        name="accountName"
        control={control}
        rules={{ required: "Account name is required" }}
        render={({ field }) => (
          <Input
            label="Account name"
            value={field.value}
            onChange={field.onChange}
            hasError={!!errors.accountName}
          />
        )}
      />
      {errors.accountName && (
        <span className="error">{errors.accountName.message}</span>
      )}
    </FormSection>
  );

  const renderInternationalFields = () => (
    <>
      <FormSection>
        <Controller
          name="sortCode"
          control={control}
          render={({ field }) => (
            <Input
              label="Sort code (optional)"
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </FormSection>

      <FormSection>
        <Controller
          name="iban"
          control={control}
          render={({ field }) => (
            <Input
              label="IBAN (optional)"
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </FormSection>

      <FormSection>
        <Controller
          name="routingNumber"
          control={control}
          render={({ field }) => (
            <Input
              label="Routing number (optional)"
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </FormSection>

      <FormSection>
        <Controller
          name="swiftCode"
          control={control}
          render={({ field }) => (
            <Input
              label="Swift code (optional)"
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </FormSection>
    </>
  );

  const renderNigerianFields = () => (
    <FormSection>
      <Controller
        name="bvn"
        control={control}
        rules={{
          required: "BVN is required",
          minLength: {
            value: 10,
            message: "BVN must be 10 digits",
          },
          pattern: {
            value: /^[0-9]+$/,
            message: "BVN must be numeric",
          },
        }}
        render={({ field }) => (
          <Input
            label="BVN"
            value={field.value}
            onChange={field.onChange}
            hasError={!!errors.bvn}
          />
        )}
      />
      {errors.bvn && <span className="error">{errors.bvn.message}</span>}
    </FormSection>
  );

  const renderFormFields = () => {
    switch (selectedCountry) {
      case 'NG':
        return (
          <>
            {renderNigerianFields()}
            {renderCommonFields()}
            {renderAccountNameField()}
          </>
        );
      case undefined:
      case '':
        return null;
      default:
        return (
          <>
            {renderAccountNameField()}
            {renderCommonFields()}
            {renderInternationalFields()}
          </>
        );
    }
  };

  
  return (
    <div>
      <Loader isLoading={loading} />
      <BusinessTitle>Business settlement account</BusinessTitle>
      <BusinessText>
        Sed ut perspiciatis unde omnis iste natus error sit voluptatem
        accusantium.
      </BusinessText>

      <FormSection>
        <Controller
          name="country"
          control={control}
          rules={{ required: "Country is required" }}
          render={({ field }) => (
            <CountrySelect value={field.value} onChange={field.onChange} allCountries={true}/>
          )}
        />
        {errors.country && (
          <span className="error">{errors.country.message}</span>
        )}
      </FormSection>

      <FormSection>
        <SettlementCurrencySelector
          countryValue={selectedCountry}
          control={control}
          setValue={setValue}
        />
      </FormSection>

      {renderFormFields()}

      <BtnWrapper>
        <Button
          label="Continue"
          width="424px"
          height="48px"
          fontSize="16px"
          onClick={handleSubmit(onSubmit)}
          disabled={!isValid || loading}
        />
      </BtnWrapper>
    </div>
  );
};

export default SettlementDetails;
