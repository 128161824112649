import styled from "styled-components";

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .uploaded-content {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  p {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    text-align: left;
    margin-top: 10px;
    margin: 0;
  }
  .uploaded-copy {
    color: #007912;
    font-weight: 400;
  }
`;

export const Space = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DocumentUploadBox = styled.div`
  border: 1px solid #d1d1d1;
  padding: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  margin: auto;
  border-radius: 8px;
`;

export const UploadButton = styled.button`
  background: none;
  border: none;
  color: #1890ff;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    text-decoration: underline;
  }
`;

export const UploadWrapper = styled.div`
  display: flex;
  gap: 10px;
  p {
    color: #8f8f8f !important;
  }
`;
