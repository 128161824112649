import React from "react";
import { ReactComponent as AccountCreationIcon } from "../../assets/account-creation-icon.svg";
import { Flex, PendingWrapper } from "./style";

interface PendingAccountProps {
  currencyCode?: string;
}

const PendingAccount: React.FC<PendingAccountProps> = ({ currencyCode }) => {
  return (
    <PendingWrapper>
      <Flex>
        <AccountCreationIcon />

        <div>
          <h1>Your {currencyCode} account details is on its way</h1>
          <p>
            We are currently reviewing your detials. You will get an update
            within 24 - 72 hours.
          </p>
        </div>
      </Flex>
    </PendingWrapper>
  );
};

export default PendingAccount;
