import React, { useContext } from "react";
import { BusinessText, BusinessTitle, BtnWrapper } from "../styles";
import { Button } from "../../../components/common/Button";
import { Controller, useForm } from "react-hook-form";
import FormField from "../../../components/FormField/FormField";
import FormSection from "../components/FormSection";
import { InputPhone } from "../../../components/common/InputPhone";
import SocialMediaPicker from "../components/SocialMediaPicker";
import { BusinessContact } from "../Types";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { addBusinessContact } from "../redux/actions";
import Loader from "../../../components/common/Loader";
import { NetworkErrorAlertContext } from "../../../context/NetworkErrorAlert";


type Props = {
  handleNext: () => void;
  handlePrevious: () => void;
};

const BusinessContactDetails = ({ handleNext, handlePrevious }: Props) => {
  const dispatch = useAppDispatch();
  const { businessId, userObj } = useAppSelector((state) => state.users);
  const { loading } = useAppSelector((state) => state.onboarding || {});

  const { onShowAlert: onShowErrorAlert } =
    useContext(NetworkErrorAlertContext) || {};

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<BusinessContact>({ mode: 'onChange' });

  const handleCreateBusinessContact = async (data: BusinessContact) => {
    const payload = {
      businessId: businessId,
      email: data.email,
      ...data,
    };
    const action = await dispatch(addBusinessContact(payload));

    if (addBusinessContact.fulfilled.match(action)) {
      handleNext();
    } else if (addBusinessContact.rejected.match(action)) {
      onShowErrorAlert("", String(action.payload));
    }
  };

  return (
    <div>
      <Loader isLoading={loading} />
      <BusinessTitle>Business Information</BusinessTitle>
      <BusinessText>
        Sed ut perspiciatis unde omnis iste natus error sit voluptatem
        accusantium
      </BusinessText>

      <FormSection>
        <FormField
          name="email"
          control={control}
          label="Business e-mail address"
          required={true}
          errors={errors}
          rules={{
            required: "Email is required",
            pattern: {
              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
              message: "Invalid email address",
            },
          }}
        />
      </FormSection>

      <FormSection>
        <Controller
          name="phone"
          control={control}
          rules={{
            required: "Phone number is required",
            pattern: {
              value: /^\+?[1-9]\d{1,14}$/,
              message: "Invalid phone number",
            },
          }}
          render={({ field }) => (
            <InputPhone
              label="Phone number"
              field={field}
              hasError={!!errors.phone}
            />
          )}
        />
        {errors.phone && <span className="error">{errors.phone.message}</span>}
      </FormSection>

      <FormSection>
        <Controller
          name="website"
          control={control}
          rules={{
            required: false,
            pattern: {
              value: /^(?:[a-zA-Z]+:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/,
              message: "Please enter a valid URL (e.g., www.example.com)",
            },
          }}
          render={({ field: { onChange, value } }) => (
            <SocialMediaPicker
              value={value || undefined}
              onChange={onChange}
              addonBefore={"https://"}
              placeholder="Business website (optional)"
            />
          )}
        />

        {errors.website && (
          <span className="error">{errors.website.message}</span>
        )}
      </FormSection>

      <FormSection>
        <h1 className="business-logo-copy">Social media username/links</h1>
        <p className="business-logo-text">
          Sed ut perspiciatis unde omnis iste natus error sit voluptatem
          accusantium
        </p>

        <Controller
          name="socialMediaValues"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value } }) => (
            <SocialMediaPicker
              addonBefore={['Instagram', 'Linkedin', 'X (formerly known Twitter)', 'Facebook', 'WhatsApp']}
              placeholder="Enter your username or profile link here..."
              value={value || []}
              onChange={onChange}
            />
          )}
        />

        {errors.socialMediaValues && (
          <span className="error">
            {errors.socialMediaValues.message}
          </span>
        )}
      </FormSection>

      <BtnWrapper>
        <Button
          label="Previous step"
          theme="alternate"
          height="48px"
          fontSize="16px"
          width="239px"
          onClick={handlePrevious}
        />

        <Button
          onClick={handleSubmit(handleCreateBusinessContact)}
          label="Continue"
          width="239px"
          height="48px"
          fontSize="16px"
          disabled={!isValid || loading}
        />
      </BtnWrapper>
    </div>
  );
};

export default BusinessContactDetails;
