import api from "../../app/api";

export const getSettlementPayout = async (data) => {
  try {
    const response = await api.post(
      `/wallet/foreign/banktransfer/request/admin/transaction/paginated/filter`,
      data,
    );
    return response.data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const getSettlementDetails = async (data) => {
  const response = await api.post(
    `/wallet/virtual/account/collection/filter/paginated`,
    data,
  );
  return response.data;
};
