import React, { useEffect, Dispatch, SetStateAction } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { CurrencyTypeWrapper, IntlCurrency } from "./style";
import { nextVariants } from "../../../../utils";
import SameCurrency from "./SameCurrency";
import { Button } from "../../../../../../components/common/Button";
import { ReactComponent as WarningIcon } from "../../../../../../assets/icons/alert.svg";
import { CurrencyWrapper } from "../../../Withdrawal/styles";

type StepAmountProps = {
  destinationCurrency?: string;
  amount?: number;
  transferFee: { fees: number; currency: string };
  beneficiaryAmount?: number;
  onNext: () => void;
  currencyTab?: string;
  fundsFromSameCurrency?: number;
  sameCurrencyReceive?: number;
  transactionrate?: any;
  activeCurrency: string;
  selectedCountry?: string;
  setFundsFromSameCurrency?: Dispatch<SetStateAction<number>>;
  setActiveCurrency: Dispatch<SetStateAction<string>>;
};

const StepAmount = ({
  destinationCurrency,
  amount,
  transferFee,
  sameCurrencyReceive,
  onNext,
  fundsFromSameCurrency,
  setFundsFromSameCurrency,
  currencyTab,
  transactionrate,
  activeCurrency,
  setActiveCurrency,
  selectedCountry,
}: StepAmountProps) => {
  const navigate = useNavigate();
  const { currencyCode } = useParams();
  const currencyTypes: string[] = ["Same currency", "International"];

  const handleCurrencyTypeClick = (currencyType) => {
    setActiveCurrency(currencyType);
  };

  useEffect(() => {
    if (
      selectedCountry &&
      amount > 100 &&
      destinationCurrency === currencyCode
    ) {
      setActiveCurrency("Same currency");
    }
  }, [selectedCountry, amount, destinationCurrency, currencyCode]);

  return (
    <>
      <CurrencyTypeWrapper activeCurrency={currencyCode}>
        {currencyTypes?.map((currencyType) => (
          <p
            key={currencyType}
            className={activeCurrency === currencyType ? "active" : "inactive"}
            onClick={() => handleCurrencyTypeClick(currencyType)}
          >
            {currencyType}
          </p>
        ))}
      </CurrencyTypeWrapper>

      {activeCurrency === "International" ? (
        <AnimatePresence>
          <CurrencyWrapper>
            <IntlCurrency
              variants={nextVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              <WarningIcon />
              <h1>You want to make international transfers?</h1>
              <div>
                <Button
                  label="Proceed to Klasha wire"
                  height="48px"
                  width="100%"
                  fontSize="14px"
                  onClick={() => navigate("/klasha-wire")}
                />
              </div>
            </IntlCurrency>
          </CurrencyWrapper>
        </AnimatePresence>
      ) : (
        <SameCurrency
          funds={fundsFromSameCurrency}
          sendFunds={setFundsFromSameCurrency}
          sameCurrency={currencyTab}
          beneficiaryReceive={sameCurrencyReceive}
          onNext={onNext}
          transferFee={transferFee}
          transactionrate={transactionrate}
        />
      )}
    </>
  );
};

export default StepAmount;
