import React from "react";
import { Section } from "../styles";

type FormSectionProps = {
  children: React.ReactNode;
  marginBottom?: boolean;
};

const FormSection = ({ children, marginBottom }: FormSectionProps) => (
  <Section $marginBottom={marginBottom}>
    <div>{children}</div>
  </Section>
);

export default FormSection;
