import React, {
  useState,
  useContext,
  useEffect,
} from "react";
import { usePostHog } from "posthog-js/react";
import usePostHogDeviceTypeCapture from "../../app/hooks/usePostHogDeviceTypeCapture";
import { LoginOTPWrapper } from "./style";
import {
  sendOtpToMerchant,
  verifyMerchantOtp,
} from "../../api/authAPI";
import { NetworkErrorAlertContext } from "../../context/NetworkErrorAlert";
import { getMerchant } from "../../api/usersAPI";
import { SuccessAlertContext } from "../../context/SuccessAlertContext";
import { useMutation } from "react-query";
import { AxiosError } from "axios";
import Loader from "../../components/common/Loader";
import NetworkErrorAlert from "../../components/common/alerts/NetworkErrorAlert";
import SuccessAlert from "../../components/common/alerts/SuccessAlert";
import {
  useAppSelector,
  useAppDispatch,
} from "../../app/hooks";
import { useUser } from "../../context/UserContext";
import { updateBusinessId, updateUser } from "./usersSlice";
import { fetchComplianceData } from "../Compliance/complianceSlice";
import { updateBusinessObj } from "../Settings/settingsSlice";
import { useNavigate } from "react-router-dom";
import Otp from "../../components/common/OTP";
import { GetBusinessUserRole } from "../Settings/Team/redux/actions";
import { useOnboardingV2Flag } from "../../app/hooks/useOnboardingV2Flag";

interface Props {
  setVerifyOTP: (arg: boolean) => void;
}
interface User {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  userId: number;
}

const LoginOTP = ({ setVerifyOTP }: Props) => {
  const onboardingFlag = useOnboardingV2Flag()
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const posthog = usePostHog();
  const agent = usePostHogDeviceTypeCapture();
  const [otp, setOtp] = useState("");
  const [userId, setUserId] = useState('')
  const { loading } = useAppSelector(
      (state) => state.multiUser || {},
  );

  const {
      onShowAlert,
      showAlert: errorAlert,
      alertMessage,
  } = useContext(NetworkErrorAlertContext);
  const {
      onShowAlert: onSuccess,
      showAlert: successAlert,
      alertMessage: successMessage,
  } = useContext(SuccessAlertContext);
  const {
      loginEmail,
      loginPassword,
      businessId,
  } = useAppSelector((state) => state.users);
  const { dispatch: localDispatch, updateToken } =
      useUser();

  useEffect(() => {
      if (businessId && userId) {
          dispatch(
              GetBusinessUserRole({
                  businessId: businessId,
                  userId: userId,
              }),
          );
      }
  }, [businessId, userId]);

  const postHog = (user: User) => {
      posthog?.identify(user.id.toString(), {
          email: user.email,
          full_name: `${user.firstName} ${user.lastName}`,
          userId: user.id.toString(),
      });
      posthog?.capture("login_b2b", {
          status: "success",
          user_agent: agent,
      });
  };

  const handleVerifyOTP = (values: string) => {
      const payload = {
          otp: values,
          email: loginEmail,
      };

      if (values.length < 6) {
          onShowAlert("", "The OTP code is not complete");
      } else {
          verifyOTP.mutate(payload);
      }
  };

  const verifyOTP = useMutation(verifyMerchantOtp, {
      onError: (error: AxiosError) => {
          const errorObj = error?.response?.data;
          let errorMessage = "";

          if (typeof errorObj.error === "string") {
              errorMessage = errorObj.error;
          } else if (typeof errorObj === "string") {
              errorMessage = errorObj;
          } else {
              errorMessage = "Invalid OTP";
          }

          onShowAlert(errorMessage, errorMessage);
          posthog?.capture("login_b2b", {
              status: "failure",
              failure_reason: errorMessage,
              user_agent: agent,
          });
      },
      onSuccess: async (data) => {
          setOtp("");
          const user = data?.data;
          const { accountUser } = user;
          setUserId(accountUser?.id);
          const token = user.token;
          updateToken(token);

          if (token) {
              await merchantDetailMutation.mutateAsync(
                  user.businessOwnerId,
              );
              postHog(user.accountUser);

              localDispatch({
                  type: "updateUser",
                  payload: user.accountUser,
              });
              const accountUser = {
                  ...user,
                  id: user.businessOwnerId,
              };
              dispatch(updateUser(accountUser));
          }
      },
  });

  const merchantDetailMutation = useMutation(
      getMerchant,
      {
          onError: (error: AxiosError) => {
              const errorObj = error?.response?.data;
              let errorMessage = "";

              if (typeof errorObj.error === "string") {
                  errorMessage = errorObj.error;
              } else {
                  errorMessage =
                      "Failed to get merchant details";
              }

              onShowAlert(errorMessage, errorMessage);
          },
          onSuccess: async (data, variables) => {
              const { data: responseData } = data;

              localDispatch({
                  type: "updateBusiness",
                  payload: responseData,
              });

              if (!responseData?.length) {
                  navigate(onboardingFlag ?? true ? "/auth/onboarding" : "/compliance");
              } else {
                  let busId;
                  const hasBusiness = responseData.find(
                      (bus: any) => bus.enabled,
                  );

                  if (hasBusiness) {
                      busId = hasBusiness?.id;
                      dispatch(
                          updateBusinessObj(hasBusiness),
                      );
                  } else {
                      busId = responseData[0]?.id;
                  }
                  dispatch(updateBusinessId(busId));

                  const compData = await dispatch(
                      fetchComplianceData({
                          userId: +variables,
                      }),
                  ).unwrap();
                  if (
                      compData[0]
                          ?.startupComplianceStatus ===
                          "DONE" ||
                      hasBusiness?.approved
                  ) {
                      navigate("/dashboard");
                  } else {
                      navigate(onboardingFlag ?? true ? "/auth/onboarding" : "/compliance");
                  }
              }
              setVerifyOTP(false);
          },
      },
  );

  const handleResend = () => {
      const payload = {
          email: loginEmail,
          password: loginPassword,
      };
      resendOTP.mutate(payload);
  };

  const resendOTP = useMutation(sendOtpToMerchant, {
      onError: (error: AxiosError) => {
          const errorObj = error?.response?.data;
          let errorMessage = "";

          if (typeof errorObj.error === "string") {
              errorMessage = errorObj.error;
          } else if (
              typeof errorObj.message === "string"
          ) {
              errorMessage = errorObj.message;
          } else {
              errorMessage = "Failed to resend OTP";
          }

          onShowAlert(errorMessage, errorMessage);
      },
      onSuccess: async () => {
          onSuccess("Check your email for the OTP");
          setOtp("");
      },
  });

  return (
      <LoginOTPWrapper>
          {errorAlert && (
              <NetworkErrorAlert message={alertMessage} />
          )}
          {successAlert && (
              <SuccessAlert message={successMessage} />
          )}

          <Loader
              isLoading={
                  verifyOTP.isLoading ||
                  merchantDetailMutation.isLoading ||
                  resendOTP.isLoading || loading
              }
          />

          <Otp
              smallText="To log in, enter the verification code sent to your email
        address"
              buttonLabel="Continue"
              value={otp}
              onChange={setOtp}
              onClick={() => handleVerifyOTP(otp)}
              handleClick={handleResend}
              onClose={() => setVerifyOTP(false)}
          />
      </LoginOTPWrapper>
  );
};

export default LoginOTP;