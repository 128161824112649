import React, { ReactNode, useContext } from "react";
import { Container, OnboardingWrapper, BodyDiv, TopDiv, GoToDashboard } from "../styles";
import { ReactComponent as Logo } from "../../../assets/icons/logo.svg";
import NetworkErrorAlert from "../../../components/common/alerts/NetworkErrorAlert";
import { NetworkErrorAlertContext } from "../../../context/NetworkErrorAlert";
import { useNavigate } from "react-router-dom";

type Props = {
  children: ReactNode;
};

const OnBoardingWrapper = ({ children }: Props) => {
  const { showAlert, alertMessage } = useContext(NetworkErrorAlertContext);
  const navigate = useNavigate()

  return (
    <Container>
      {showAlert && <NetworkErrorAlert message={alertMessage} />}

      <OnboardingWrapper>
        <TopDiv>
          <Logo />
          <GoToDashboard onClick={() => navigate('/dashboard')}>Go to dashboard</GoToDashboard>
        </TopDiv>
        <BodyDiv>{children}</BodyDiv>
      </OnboardingWrapper>
    </Container>
  );
};

export default OnBoardingWrapper;
