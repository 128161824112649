import React, { useState, useRef } from "react";
import UploadedFIleIcon from "../../../../assets/icons/uploaded-file-icon.svg";
import DeleteFileIcon from "../../../../assets/icons/delete-file.icon.svg";
import { ReactComponent as UploadIcon } from "../../../../assets/icons/upload-icon.svg";
import {
  DocumentUploadBox,
  Flex,
  Space,
  UploadButton,
  UploadWrapper,
} from "./styles";

interface Props {
  onFileChange: (file: File | null) => void;
  error?: string;
  showExtra?: boolean;
}

const DocumentUpload: React.FC<Props> = ({
  onFileChange,
  error,
  showExtra = false,
}) => {
  const [file, setFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      setFile(selectedFile);
      onFileChange(selectedFile);
    }
  };

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDeleteFile = () => {
    setFile(null);
    onFileChange(null);
  };

  return (
    <Space>
      {!file ? (
        <DocumentUploadBox>
          <input
            type="file"
            onChange={handleFileChange}
            style={{ display: "none" }}
            ref={fileInputRef}
          />
          <UploadButton onClick={handleUploadClick}>
            <UploadWrapper>
              <UploadIcon />
              {showExtra && (
                <div>
                  <h1>Upload file</h1>
                  <p>JPEG or PNG less than 5MB</p>
                </div>
              )}
            </UploadWrapper>
          </UploadButton>
          {error && <p>{error}</p>}
        </DocumentUploadBox>
      ) : (
        <DocumentUploadBox>
          <Flex>
            <div className="uploaded-content">
              <img src={UploadedFIleIcon} />
              <span>
                <p>{file.name}</p>
                <p className="uploaded-copy">Uploaded</p>
              </span>
            </div>
            <button onClick={handleDeleteFile}>
              <img src={DeleteFileIcon} />
            </button>
          </Flex>
        </DocumentUploadBox>
      )}
    </Space>
  );
};

export default DocumentUpload;
