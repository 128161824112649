import styled from "styled-components";

export const Wrapper = styled.div`
  text-align: center;
  width: 100%;
`;

export const Title = styled.h1`
  font-family: Athletics, serif;
  font-size: 28px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: -0.02em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

export const Description = styled.p`
  font-family: Inter, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #767676;
`;

export const ContentWrapper = styled.div`
  max-width: 40%;
  margin: 0 auto auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
  padding: 0 40px 40px;

  @media (max-width: 768px) {
    max-width: 100%;
    padding: 0 20px 20px;
  }
`;

export const RateBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  border-radius: 8px;
  background-color: #f9f9f9;

  span {
    color: #000000;
    font-size: 12px;
    font-weight: 400;
    line-height: 15.6px;
  }

  .fee-box {
    display: flex;
    align-items: center;
    gap: 5px;
  }
`;

export const ItemWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;

export const DetailsWrapper = styled.div`
  padding: 20px 15px;
  font-family: Athletics, serif;
  border: 1px solid #969696;
  box-sizing: border-box;
  border-radius: 8px;
`;

export const DetailsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Section = styled.section`
  font-family: Inter, serif;
  text-align: center;

  h1 {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
  }
  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 15.6px;
    color: #757575;
    margin: 0;
  }
`;

export const RWrapper = styled.div`
  border: 1px solid #969696;
  border-radius: 10px;
  width: 100%;
`;
export const Error = styled.span`
  color: #f9123b;
  font-family: Inter;
  text-align: right;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.6px;
`;

export const InputWrapper = styled.div`
  width: 100%;
  text-align: left;
  h1 {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  p {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 15.6px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
`;

export const Heading = styled.h1`
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

export const DetailsBox = styled.div`
  padding: 20px 20px;
  font-family: Athletics;
  border: 1px solid #969696;
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 30px;
`;

export const DetailsContent = styled.div``;

export const DetailsSectionHeader = styled.div`
  padding-bottom: 8px;
  border-bottom: 1px solid #dcdcdc;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SectionTitle = styled.p`
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
`;

export const EditButton = styled.button`
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  color: #ef2c5a;
`;

export const DetailsValue = styled.p`
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: right;
  color: #000000;
`;
