
const keyToIconUrl = {
  magento: "https://assets.klasha.com/merchant-images/magento.svg",
  flutter: "https://assets.klasha.com/merchant-images/flutter.svg",
  ios: "https://assets.klasha.com/merchant-images/ios.svg",
  opencart: "https://assets.klasha.com/merchant-images/opencart.svg",
  android: "https://assets.klasha.com/merchant-images/android.svg",
  woocommerce: "https://assets.klasha.com/merchant-images/woo.svg",
  bigCommerce: "https://assets.klasha.com/merchant-images/bigc.svg",
  weebly: "https://assets.klasha.com/merchant-images/weebly.svg",
  square: "https://assets.klasha.com/merchant-images/square.svg",
  wix: "https://assets.klasha.com/merchant-images/wix.svg",
  ecwid: "https://assets.klasha.com/merchant-images/ecwid1.svg",
  react: "https://assets.klasha.com/merchant-images/react.svg",
  angular: "https://assets.klasha.com/merchant-images/angular.svg",
  vue2: "https://assets.klasha.com/merchant-images/vue.svg",
  vue3: "https://assets.klasha.com/merchant-images/vue.svg",
  javascript: "https://assets.klasha.com/merchant-images/javascript.svg",
  reactNative: "https://assets.klasha.com/merchant-images/react-native.svg",
  ionic: "https://assets.klasha.com/merchant-images/ionic.svg",
};

export default keyToIconUrl;
