import React, { useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import { Button } from "../../../../../../components/common/Button";
import { Input } from "../../../../../../components/common/Input";
import { FormWrapper } from "../../style";
import { AddUltimateBeneficial, BeneficfiaryList, Flex } from "../styles";
import CustomDatePicker from "../../../../../../components/common/CustomDateRangePicker/CustomDatePicker";
import CustomSelect from "../../../../../../components/common/CustomSelect";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import {
  addBeneficiary,
  updateFormData,
} from "../../../../redux/formDataSlice";
import useCountryOptions from "../../../../../../app/hooks/useCountryList";
import BeneficiaryCard from "../../../../components/BeneficiaryCard/BeneficiaryCard";
import Loader from "../../../../../../components/common/Loader";
import { NetworkErrorAlertContext } from "../../../../../../context/NetworkErrorAlert";
import { formatDate } from "../../../../../../utils/dateUtils";

interface formData {
  name: string;
  dob: string;
  country: string;
  ownershipPercentage: string;
  foreignBankAccountOwnershipType?: string;
}

interface Props {
  handleNext: () => void;
}

const Beneficfiary = ({ handleNext }: Props) => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<formData>() || {};
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.wallets || {});
  const formData = useAppSelector((state) => state.formData);
  const countryIncorporation = useCountryOptions();
  useContext(NetworkErrorAlertContext) || {};
  const beneficiaries = useAppSelector((state) => state.formData.beneficiaries);

  const getCountryLabel = (countryCode) => {
    const country = countryIncorporation.find(
      (item) => item.value === countryCode
    );
    return country ? country.label : countryCode;
  };
  const formatAndDispatch = (data: formData) => {
    data.dob = formatDate(data.dob);
    dispatch(updateFormData(data));
    dispatch(addBeneficiary(data));
  };

  const onSubmit = async (data: formData) => {
    const formattedDate = dayjs(data.dob);
    const formattedDateString = formattedDate.format("YYYY-MM-DD");
    data.dob = formattedDateString;
    const updatedData = {
      ...data,
      foreignBankAccountOwnershipType: "BENEFICIARY",
    };
    dispatch(updateFormData(updatedData));
    handleNext();
  };

  const handleAddBeneficiary = (data: formData) => {
    const updatedData = {
      ...data,
      foreignBankAccountOwnershipType: "BENEFICIARY",
    };
    formatAndDispatch(updatedData);
  };

  React.useEffect(() => {
    setValue("name", formData.name);
    setValue("country", formData.country);
    setValue("ownershipPercentage", formData.ownershipPercentage);
  }, [formData, setValue]);

  return (
    <FormWrapper>
      <Loader isLoading={loading} />
      <div></div>
      <>
        <div>
          <Controller
            name="name"
            rules={{ required: true }}
            control={control}
            render={({ field }) => (
              <Input
                label="Full name"
                value={field.value}
                onChange={field.onChange}
                hasError={errors?.name?.type === "required"}
              />
            )}
          />
          {errors?.name?.type === "required" && (
            <span className="error">Field is required</span>
          )}
        </div>
        <div>
          <Controller
            name="dob"
            rules={{ required: true }}
            control={control}
            render={({ field }) => (
              <CustomDatePicker {...field} placeholder="DOB" />
            )}
          />
          {errors?.dob?.type === "required" && (
            <span className="error">Field is required</span>
          )}
        </div>
        <Flex>
          <div className="form-wrapper">
            <Controller
              name="country"
              rules={{ required: true }}
              control={control}
              render={({ field }) => (
                <CustomSelect
                  placeholder="Tax residency"
                  options={countryIncorporation}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                  field={field}
                  $width="100%"
                />
              )}
            />
            {errors?.country?.type === "required" && (
              <span className="error">Field is required</span>
            )}
          </div>

          <div className="form-wrapper">
            <Controller
              name="ownershipPercentage"
              control={control}
              rules={{
                required: "Ownership percentage is required",
                min: {
                  value: 1,
                  message: "Ownership percentage must be at least 1",
                },
                max: {
                  value: 100,
                  message: "Ownership percentage cannot exceed 100",
                },
              }}
              render={({ field }) => (
                <div>
                  <Input
                    label="Ownership %"
                    type="number"
                    value={field.value}
                    onChange={field.onChange}
                    hasError={!!errors?.ownershipPercentage}
                  />
                  {errors?.ownershipPercentage && (
                    <span className="error">
                      {errors.ownershipPercentage.message}
                    </span>
                  )}
                </div>
              )}
            />
          </div>
        </Flex>
      </>

      <BeneficfiaryList>
        {beneficiaries
          .filter(
            (data) => data.foreignBankAccountOwnershipType === "BENEFICIARY"
          )
          .slice(0, 2)
          .map((data) => (
            <BeneficiaryCard
              key={data.name}
              name={data.name}
              country={getCountryLabel(data?.country)}
              cent={`${data.ownershipPercentage}%`}
              date={data.dob}
            />
          ))}
      </BeneficfiaryList>

      <AddUltimateBeneficial>
        <button onClick={handleSubmit(handleAddBeneficiary)}>
          + Add another beneficiary
        </button>
      </AddUltimateBeneficial>

      <Button label="Continue" onClick={handleSubmit(onSubmit)} height="40px" />
    </FormWrapper>
  );
};

export default Beneficfiary;
