import React from "react";
import { getCurrency, getCountryByAbbreviation } from "country-currency-map";

import styled from "styled-components";
import getSymbolFromCurrency from "currency-symbol-map";
import { countries } from "countries-list";
import { sortCurrencyObjectByNameFunction } from "./format";
import { addCommasToNumber } from "../pages/Cards/utils";

const SelectedDropdownOption = styled.div`
  display: flex;
  align-items: center;

  & > li {
    margin-right: 10px;
  }
`;

export const getCurrencyOptions = (currencyList) => {
  return currencyList.map((curr) => {
    let theFullName;
    if (curr === "GHS") {
      theFullName = "Ghana Cedis";
    } else if (curr === "ZMW") {
      theFullName = "Zambian Kwacha";
    } else {
      const regex = /\((.*?)\)/i;
      theFullName = getCurrency(curr)?.name;
      if (theFullName) {
        theFullName = theFullName.replace(regex, "").trimEnd();
      }
    }
    return {
      value: curr,
      fullName: theFullName,
      label: (
        <SelectedDropdownOption>
          <li
            className={`currency-flag currency-flag-${curr?.toLowerCase()}`}
          />{" "}
          {curr}
        </SelectedDropdownOption>
      ),
    };
  });
};

export const currencyList = [
  { label: "AUD", value: "AUD" },
  { label: "CAD", value: "CAD" },
  { label: "CNY", value: "CNY" },
  { label: "GBP", value: "GBP" },
  { label: "JPY", value: "JPY" },
  { label: "KES", value: "KES" },
  { label: "NGN", value: "NGN" },
  { label: "USD", value: "USD" },
  { label: "INR", value: "INR" },
  { label: "TZS", value: "TZS" },
];

export const allCurrenciesList = () => {
  const curListSet: any = new Set();

  for (const [key, value] of Object.entries(countries)) {
    if (key === "AQ") continue;
    const currencies = value.currency.split(",");

    curListSet.add(currencies[0]);
  }

  const curList = [...curListSet];
  const currOptions = curList.map((cur) => ({
    label: cur,
    value: cur,
  }));

  const sortedCurr = [...currOptions].sort(sortCurrencyObjectByNameFunction);

  return sortedCurr;
};

type FormattingType = "amount" | "rate";
export const formatCurrencyAmount = (
  currencyCode?: string,
  amount?: string | number,
  decimalPlaces = 2,
  type?: FormattingType
) => {
  if (amount == null || isNaN(Number(amount))) {
    return "Invalid amount";
  }

  const intAmount = Number(amount);
  let formattedAmount: string;

  if (type === "rate" && Number.isInteger(intAmount)) {
    formattedAmount = intAmount.toFixed(decimalPlaces);
  } else if (type === "rate") {
    formattedAmount = intAmount.toString();
  } else {
    formattedAmount = intAmount.toFixed(decimalPlaces);
  }

  const withCommas = addCommasToNumber(formattedAmount);
  const withZeroWidthSpace = `${withCommas}\u200B`;

  const currSymbol = getSymbolFromCurrency(currencyCode);
  return currSymbol
    ? `${currSymbol}${withZeroWidthSpace}`
    : `${currencyCode}${withZeroWidthSpace}`;
};

export const formatCurrencyCodeAmount = (
  currencyCode: string,
  amount: number | string
) => {
  const intAmount = +amount;
  const amountWithDecimals = intAmount.toFixed(2);

  const formattedAmount = parseFloat(amountWithDecimals).toLocaleString(
    undefined,
    {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }
  );

  return `${currencyCode} ${formattedAmount}`;
};

export const formatNumber = (number) => {
  const strNumber = String(number);
  const parts = strNumber.split(".");
  const integerPart = parts[0]
    .split("")
    .reverse()
    .map((digit, index) => (index > 0 && index % 3 === 0 ? `${digit},` : digit))
    .reverse()
    .join("");

  let decimalPart = parts[1] ? parts[1] : "00";
  decimalPart = decimalPart.padEnd(2, "0");
  const formattedNumber = `${integerPart}.${decimalPart}`;

  return formattedNumber;
};

export const formatNewKwireAmount = (
  currencyCode: string,
  amount: string | number
) => {
  const currSymbol = getSymbolFromCurrency(currencyCode);
  if (currSymbol) {
    return `${currSymbol}${amount}`;
  }
  return `${currencyCode}${amount}`;
};

export const getCountryName = (inputCode: string) => {
  const countryFromAbbreviation = getCountryByAbbreviation(inputCode);
  if (countryFromAbbreviation) {
    return countryFromAbbreviation;
  }
};
