import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  margin-bottom: 24px;
  font-family: "Inter";
  overflow: hidden;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
  border-bottom:  1px solid #f0f0f0;
`;

export const Item = styled.div`
  span {
    font-size: 10px;
    font-weight: 400;
    line-height: 15.6px; 
    color: #767676;
  }

  p{
    font-family: "Athletics";
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.4px;
    color: #000000;
    margin: 0;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  
  button {
    cursor: pointer;
  }
`;
