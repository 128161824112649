import BusinessInfo from "../steps/BusinessInfo";
import BusinessContactDetails from "../steps/BusinessContactDetails";
import { StepData } from "../Types";
import Director from "../steps/Director";
import SettlementDetails from "../steps/SettlementDetails";
import BusinessDocuments from "../steps/BusinessDocuments";
import AdditionalDetails from "../steps/AdditionalDetails";
import Declaration from "../steps/Declaration";

export const stepsData: StepData[] = [
  {
    number: 0,
    title: "Personal Information",
    description: "Enter your personal information to get started",
  },
  {
    number: 1,
    title: "Business information",
    description: "Provide basic details about your business",
    component: BusinessInfo,
  },
  {
    number: 2,
    title: "Business contact details",
    description: "Add your business primary contact's information",
    component: BusinessContactDetails,
  },
  {
    number: 3,
    title: "Business director details",
    description: "Provide details for the business directors/trustees.",
    component: Director,
  },
  {
    number: 4,
    title: "Business settlement account",
    description: "Set up the account for business settlements.",
    component: SettlementDetails,
  },
  {
    number: 5,
    title: "Business documents",
    description: "Upload required business documents.",
    component: BusinessDocuments,
  },
  {
    number: 6,
    title: "Additional details",
    description: "Tell us more about your business.",
    component: AdditionalDetails,
  },
  {
    number: 7,
    title: "Declaration",
    description: "Confirm your information and agree to the terms.",
    component: Declaration,
  },
];
