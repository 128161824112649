import { useEffect, useState } from "react";
import { getBusinessCategory } from "../../pages/Onboarding/redux/actions";
import { useAppDispatch } from "../hooks";
import * as Sentry from "@sentry/react";

type BusinessCategory = {
  id: string;
  name: string;
};

export const useBusinessCategory = () => {
  const dispatch = useAppDispatch();
  const [businessCategories, setBusinessCategories] = useState<
    BusinessCategory[]
  >([]);

  useEffect(() => {
    const fetchBusinessCategories = async () => {
      const action = await dispatch(getBusinessCategory() as any);
      if (getBusinessCategory.fulfilled.match(action)) {
        const options = action.payload
          .filter((cat) => cat.name !== "Others")
          .map((category) => ({
            label: category.name,
            value: category.id,
          }));
        setBusinessCategories(options);
      } else if (getBusinessCategory.rejected.match(action)) {
        Sentry.captureException(action.payload);
      }
    };

    fetchBusinessCategories();
  }, [dispatch]);

  return { businessCategories };
};
