import styled from "styled-components";
import { Table } from "antd";


export const TableContainer = styled.div`
  display: none;
  font-family: "Inter";

  @media (min-width: 768px) {
    display: block;
    width: 100%;
    margin-top: 32px;
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    overflow: hidden;

    h3 {
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #000000;
      margin-bottom: 0;
      padding: 16px;
    }
  }
`;

export const MobileContainer = styled.div`
  width: 100%;
  margin-top: 48px;

  h3 {
    font-family: "Inter";
    font-size: 18px;
    font-weight: 500;
    line-height: 28px; 
    color: #000000;
    margin-bottom: 0;
    padding-bottom: 15px;
    border-bottom: 1px solid #f0f0f0;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

export const Wrapper = styled.div`
  font-family: "Inter";
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
  border-top: 1px solid #f0f0f0;
  padding: 10px 16px;
`;

export const Filters = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid #f0f0f0;

  @media (min-width: 768px) {
   gap: 0; 
  }
`;

export const ListsContainer = styled.ul`
  padding: 0;
  list-style: none;
  margin: 15px 0;
`;

export const Lists = styled.li`
  font-family: "Inter";
  font-style: normal;
  width: 100%;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 16px 0;

  h1 {
    margin: 0;
    font-family: "Athletics";
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.4px;
    color: #000000;
    padding: 0 16px;
  }
`;

export const Line = styled.div`
  height: 1px;
  background-color: #f0f0f0;
  margin-top: 10px;
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 10px 0 16px;
  font-weight: 400;
  font-family: "Inter";

  div {
    width: 50%;

    p {
      margin-bottom: 2px;
      font-size: 10px;
      color: #767676;
      line-height: 15.6px;
    }

    span {
      font-size: 16px;
      color: #000000;
      line-height: 24px;
    }
  }
`;

export const TableWrapper = styled(Table)`
  width: 100%;
  font-family: "Inter";

  & thead[class="ant-table-thead"] > tr > th {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #5F5F5F;
    background-color: #F9F9F9;
    padding: 12px 0 12px 60px;
  }

  & thead[class="ant-table-thead"] > tr > th:first-child {
    padding: 12px 0 12px 16px;
  }

  & thead[class="ant-table-thead"] > tr > th::before {
    display: none;
  }

  & tbody[class="ant-table-tbody"] > tr > td {
    font-weight: 400;
    font-size: 12px;
    line-height: 15.6px;
    color: #484848;
    padding: 24px 0 24px 60px;
  }

  & tbody[class="ant-table-tbody"] > tr > td:first-child {
    padding: 24px 0 24px 16px;
  }

  & li[class~="ant-pagination-prev"],
  & li[class~="ant-pagination-next"] {
    width: 32px;

    & button[class="ant-pagination-item-link"] {
      border: 1px solid #e8e8e8;
      border-radius: 8px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #a39d9e;

      &:hover {
        background-color: #e8e8e866;
      }
    }

    & button[class="ant-pagination-item-link"]:disabled {
      color: #a39d9e51;
      border: none;
      background-color: white;
    }
  }

  & li[class~="ant-pagination-item"] {
    min-width: 32px;
    border-radius: 8px !important;
    border: 1px solid #e8e8e8 !important;
    display: flex;
    align-items: center;
    justify-content: center;

    & > a {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #a39d9e;
    }

    &:hover {
      background-color: #e8e8e866;
    }
  }
  .ant-table-pagination.ant-pagination {
    margin: 20px;
  }

  & li[class~="ant-pagination-item-active"] {
    border: 1px solid #e8e8e8;
    background-color: #e8e8e8;

    & > a {
      color: #000000;
    }
  }
`;

export const Empty = styled.div`
   margin: 50px 0 24px 0;
  text-align: center;

  h1 {
    font-family: "Athletics";
    font-size: 24px;
    font-weight: 400;
    line-height: 32px; 
    letter-spacing: -0.48px;
    color: #000000;
  }

  p {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 400;
    line-height: 16px; 
    color: #8F8F8F;
    margin: 0;
  }
`;
