import React, { useContext } from "react";
import { Acknowledgement, BusinessText, BusinessTitle, DeclarationText, DeclarationWrapper, KlashaSpanHighlight } from "../styles";
import { Button } from "../../../components/common/Button";
import { Controller, useForm } from "react-hook-form";
import { Types } from "../Types";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { addBusinessDeclaration } from "../redux/actions";
import { NetworkErrorAlertContext } from "../../../context/NetworkErrorAlert";
import Loader from "../../../components/common/Loader";
import { useNavigate } from "react-router-dom";

type Props = {
  handleNext: () => void;
};

const Declaration = ({ handleNext }: Props) => {

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { businessId, userObj } = useAppSelector((state) => state.users);
  const { loading } = useAppSelector((state) => state.onboarding || {});
  const { onShowAlert: onShowErrorAlert } =
    useContext(NetworkErrorAlertContext) || {};

  const {
    handleSubmit,
    control,
    formState: { isValid, errors },
  } = useForm<Types>({
    mode: "onChange",
  });

  const handleDeclarationSubmit = async () => {
    const payload = {
      businessId,
      ownerId: userObj.businessOwnerId,
    }
    const action = await dispatch(addBusinessDeclaration(payload))
    if (addBusinessDeclaration.fulfilled.match(action)) {
      navigate('/dashboard')
    } else if (addBusinessDeclaration.rejected.match(action)) {
      onShowErrorAlert('', String(action.payload))
    }
  }

  const creatorName = userObj.accountUser?.firstName + " " + userObj.accountUser?.lastName
  const businessName = userObj.accountUser?.businessName

  return (
    <DeclarationWrapper>
      <Loader isLoading={loading} />
      <BusinessTitle>Declaration</BusinessTitle>
      <BusinessText>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium</BusinessText>
      <DeclarationText>
        I <span>{creatorName}</span> confirm and agree, on behalf of <span>{businessName || 'my business'}</span>,
        that the above responses are current, accurate, and
        reflective of our institution&apos;s business and anti-money laundering information.
        <br />
        <br />
        That I am duly authorised to sign for and act on behalf of the business and that no approval is required from any other person.
        <br />
        <br />
        That we will notify Klasha immediately of any changes to the information provided herein.
        <br />
        <br />
        That we authorise all background searches, due-diligence enquiries and use of our information as set out in Klasha&apos;s AML and KYC Policies.
      </DeclarationText>

      <Acknowledgement>
        <Controller
          name="declaration"
          control={control}
          rules={{ validate: (value) => value === true }}
          render={({ field }) => (
            <input
              type="checkbox"
              checked={field.value || false}
              onChange={(e) => field.onChange(e.target.checked)}
            />
          )}
        />
        <span>I acknowledge that I have read and do hereby accept the terms and conditions in <KlashaSpanHighlight><Link target="__blank" to={'https://assets.klasha.com/KLASHA+MERCHANT+SERVICE+AGREEMENT.pdf'}>Klasha&apos;s Merchant Agreement,</Link> Privacy Policy</KlashaSpanHighlight></span>
      </Acknowledgement>

      <Button
        onClick={handleSubmit(handleDeclarationSubmit)}
        label="I agree"
        width="494px"
        height="48px"
        fontSize="16px"
        disabled={!isValid}
      />
    </DeclarationWrapper>
  );
};

export default Declaration;
