import { Content, ContentWrapper, Heading, PendingStepsCount, StepWrapper } from "./styles";
import { getLastOnboardingSteps } from "./redux/actions";
import React, { useEffect, useState, useCallback } from "react";
import OnBoardingWrapper from "./components/OnBoardingWrapper";
import { stepsData } from "./components/StepData";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useNavigate } from "react-router-dom";
import { updateBusinessId } from "../Login/usersSlice";
import { updateBusinessType } from "./redux/slice";

const Onboarding: React.FC = () => {
  const dispatch = useAppDispatch();
  const { businessId } = useAppSelector((state) => state.users);
  const { business } = useAppSelector((state) => state.settings);
  const navigate = useNavigate()
  const businessName = `${business?.name}`;
  const greeting = "Welcome to Klasha!";
  const copy = "Let’s help you setup your account.";
  const [currentStep, setCurrentStep] = useState(1);

  const handleChangeStep = (index: number) => {
    if (index >= 0 && index < stepsData.length) {
      setCurrentStep(index);
    }
  };

  const renderStepContent = () => {
    const currentStepData = stepsData[currentStep];
    const StepComponent = currentStepData?.component;

    if (StepComponent) {
      return (
        <StepComponent
          handleNext={() => handleChangeStep(currentStep + 1)}
          handlePrevious={() => handleChangeStep(currentStep - 1)}
        />
      );
    }

    return null;
  };

  const stepsItems = stepsData.map((step) => ({
    title: step.title,
    description: step.description,
  }));

  const fetchNextStep = useCallback(async () => {
    const resultAction = await dispatch(getLastOnboardingSteps({ businessId }));

    if (getLastOnboardingSteps.fulfilled.match(resultAction)) {
      const { nextStep, lastStep, business } = resultAction.payload;
      dispatch(updateBusinessId(business?.id));
      dispatch(updateBusinessType(business?.businessType))
      nextStep === 'DONE' && navigate('/dashboard')

      const stepNumber = parseInt(nextStep.replace("STEP", ""), 10);
      setCurrentStep((prevStep) =>
        prevStep !== stepNumber ? stepNumber : prevStep
      );
    }
  }, [dispatch, businessId]);
  
  useEffect(() => {
    fetchNextStep();
  }, [fetchNextStep]);

  return (
    <OnBoardingWrapper>
      <Heading>
        <span>Hello {businessName}</span>, {greeting}
      </Heading>
      <Heading>{copy}</Heading>
      <Content>
        <section>
          <StepWrapper
            direction="vertical"
            current={currentStep}
            items={stepsItems}
          />
        </section>

        <ContentWrapper>{renderStepContent()}</ContentWrapper>
        <PendingStepsCount>{8 - currentStep} step{currentStep < 7 && 's'} left to complete</PendingStepsCount>
      </Content>
    </OnBoardingWrapper>
  );
};

export default Onboarding;
