import styled from "styled-components";

export const Wrapper = styled.div`
  display: ${(props: { $show: boolean }) => (props.$show ? "block" : "none")};

  @media (min-width: 768px) {
    display: ${(props: { $show: boolean }) => (props.$show ? "flex" : "none")};
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f0f0f0;
    padding: 12px 16px;
  }
`;

export const FilterAndButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 768px) {
    justify-content: flex-start;
    gap: 15px;
  }
`;

export const FilterButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  width: 94px;
  height: 40px;
  border: 1px solid #000000;
  border-radius: 8px;
  padding: 0 20px;
    
  span {
    font-weight: 600;
    font-size: 12px;
    line-height: 15.6px;
    color: #000000;
  }
`;

export const FilterBox = styled.div`
  padding: 32px 24px;
`;

export const FilterBoxItem = styled.div`
  margin-bottom: 24px;
`;
export const FilterBoxItemTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #222223;
  margin-bottom: 8px;
`;

export const FilterBoxFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FilterFooterResetButton = styled.button`
  width: 78px;
  height: 40px;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 6px;
`;

export const FilterFooterApplyButton = styled.button`
  width: 69px;
  height: 40px;
  background: #000000;
  border-radius: 6px;
  color: #ffffff;
`;
