import React, { useRef } from "react";
import UploadedFileIcon from "../../../assets/icons/upload-icon.svg"
import DeleteFileIcon from "../../../assets/icons/delete-file.icon.svg";
import { ReactComponent as UploadIcon } from "../../../assets/icons/upload-icon.svg";
import { DocumentUploadBox, Flex, Space, UploadButton } from "../styles";

interface FileUploadProps {
  file: File | null;
  fileUrl: string | null;
  uploading: boolean;
  error: string | null;
  onFileSelect: (file: File) => void;
  onFileDelete: () => void;
  accept?: string;
  maxSize?: number;
  uploadPath: string;
  title?: string;
  subtitle?: string;
}

const FileUpload: React.FC<FileUploadProps> = ({
  file,
  fileUrl,
  uploading,
  error,
  onFileSelect,
  onFileDelete,
  accept = "image/jpeg,image/png",
  maxSize = 5 * 1024 * 1024,
  uploadPath,
  title = "Upload File",
  subtitle = "JPEG or PNG less than 5MB",
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      if (selectedFile.size > maxSize) {
        console.error(`File size must be less than ${maxSize / (1024 * 1024)}MB.`);
        return;
      }
      onFileSelect(selectedFile);
    }
  };

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <Space>
      {!file ? (
        <DocumentUploadBox>
          <input
            type="file"
            accept={accept}
            onChange={handleFileChange}
            style={{ display: "none" }}
            ref={fileInputRef}
          />
          <Flex>
            <UploadButton onClick={handleUploadClick}>
              <UploadIcon />
              <div>
                <h4>{title}</h4>
                <span>{subtitle}</span>
              </div>
            </UploadButton>
          </Flex>
          {error && <p>{error}</p>}
        </DocumentUploadBox>
      ) : (
        <DocumentUploadBox>
          <Flex>
            <div className="uploaded-content">
              <img src={UploadedFileIcon} alt="Uploaded file" />
              <span>
                <p>{file.name}</p>
                <p className="uploaded-copy">
                  {uploading ? "Uploading..." : "Uploaded"}
                </p>
              </span>
            </div>
            <button onClick={onFileDelete}>
              <img src={DeleteFileIcon} alt="Delete file" />
            </button>
          </Flex>
        </DocumentUploadBox>
      )}
    </Space>
  );
};

export default FileUpload;