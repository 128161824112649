import { useFeatureFlagEnabled } from "posthog-js/react";

export const useOnboardingV2Flag = (): boolean => {
  const isEnabled = useFeatureFlagEnabled("onboarding-v2");

  if (process.env.NODE_ENV === "production") {
    return isEnabled ?? true;
  }

  return isEnabled ?? false;
};
