import styled from "styled-components";

export const ErrorText = styled.div`
  display: flex;
  margin-top: 4px;
  align-items: center;

  & span {
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: var(--primary-pink);
    text-align: left;
    margin-left: 8px;
  }
`;

export const FormWrapper = styled.div`
  width: 100%;
`;
