import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Heading, Line, Wrapper, Error } from "../AccountInfo/style";
import CurrencySelector from "../CurrencySelector/CurrencySelector";
import { Button } from "../../../../components/common/Button";

interface Props {
  onDone: (data) => void;
  value: number;
  onValueChange: (data: number) => void;
}

const ReceiveWithNonNGN = ({ onDone, value, onValueChange }: Props) => {
  const { currencyCode } = useParams();
  const [minAmount, setMinAmount] = useState(false);

  useEffect(() => {
    if (value < 200) {
      setMinAmount(true);
    }
    if (value >= 200) {
      setMinAmount(false);
    }
    if (!value) {
      setMinAmount(false);
    }
  }, [value]);

  return (
    <Wrapper>
      <Heading>How much do you want to top up?</Heading>
      <Line />

      <CurrencySelector
        className="currency-input"
        countries={[
          {
            currencyCode: currencyCode,
            flagCode: currencyCode,
          },
        ]}
        placeholder="Amount"
        value={value}
        onValueChange={onValueChange}
        selectValue={currencyCode}
        arrow={true}
        selector={true}
      />

      {minAmount && (
        <Error className="error">
          {`Minimum amount is ${currencyCode} 200`}
        </Error>
      )}

      <Button
        label="Continue"
        theme="secondary"
        height="56px"
        fontSize="16px"
        onClick={onDone}
        disabled={!value || value < 200}
      />
    </Wrapper>
  );
};

export default ReceiveWithNonNGN;
